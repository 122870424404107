import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dentist from "../../Assets/dentist.png";
import dottedImage from "../../Assets/dotted-image.png";
import heroImage from "../../Assets/hero-image-4.png";
import Footer from "../../components/Footer/Footer";
import HomeNav from "../../components/HomeNav/HomeNav";
import "./Homepage.scss";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import seachengine from "../../Assets/searchengine.png";
import {
  faqQuestions,
  getHomeContentService,
  getNearestClinic,
  makeHttpRequest,
  submitContactUs,
} from "../../utils/httpServices/httpService";
import {encode,decode} from "js-base64"
// import { EffectCards } from 'swiper';
import "swiper/css/effect-cards";
import { EffectCards, Mousewheel, Navigation } from "swiper/modules";
// import 'swiper/swiper-bundle.min.css';
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { toast } from "react-toastify";
import dentistImg from "../../Assets/dentist.jpg";
import dentistImg1 from "../../Assets/dentist-1.jpg";
import dentistImg2 from "../../Assets/dentist-2.jpg";
import axios from "axios";
import ChatSupport from "../../components/ChatSupport/ChatSupport";
import { Skeleton, Tooltip } from "antd";
import ContactForm from "../../components/ContactForm/ContactForm";
import Loader from "../../components/Loader/Loader";
import AbsoluteLoader from "../../components/Loader/AbsoluteLoader";
import { WavingHand } from "@mui/icons-material";
import SeeHow from "../../components/SeeHow/SeeHow";
import { FALSE } from "sass";

const libraries = ["places"];

const Homepage = () => {
  const navigate = useNavigate();
  const searchBoxRefStreet = useRef(null);
  const [validLocation, setValidLocation] = useState(false);
  const [clinicsLIst, setClinicsLIst] = useState([]);

  const [details, setDetails] = useState({
    lat: 0,
    lng: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  //   libraries,
  // });

  const [accordion, setAccordion] = useState({
    state1: true,
    state2: false,
    state3: false,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  console.log(
    "state 1",
    accordion.state1,
    "state 2",
    accordion.state2,
    "state 3",
    accordion.state3
  );

  const handleAccordionButtonClick = (image, i) => {
    console.log("i", i);
    if (i === 0) {
      setAccordion({
        state1: true,
        state2: false,
        state3: false,
      });
    } else if (i === 1) {
      setAccordion({
        state1: false,
        state2: true,
        state3: false,
      });
    } else if (i === 2) {
      setAccordion({
        state1: false,
        state2: false,
        state3: true,
      });
    } else {
      setAccordion({
        state1: true,
        state2: false,
        state3: false,
      });
    }
    setSelectedImage(image);
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const imgStyle = {
    WebkitTransform: "rotate(0deg)",
    width: "100%",
  };

  const token = localStorage.getItem("token");

  const [homeContentData, setHomeContentData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  async function getHomePageData() {
    setIsLoading(true);
    try {
      const resp = await getHomeContentService();
      if (resp.code === 200) {
        setHomeContentData(resp.data);
      }
    } catch (error) {
      console.log("error get home conent api", error);
    } finally {
      setIsLoading(false);
    }
  }

  const homepage_content_base_url = process.env.REACT_APP_CMS;

  const [homeQuestions, setHomeQuestions] = useState([]);
  const [dummyTexts, setdumm] = useState([]);
  const [countryName, setCountryName] = useState("");

  const [currentSlide, setCurrentSlide] = useState();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramField = query.get("source");

  const validateSource = async () => {
    try {
      const res = await makeHttpRequest("get", `users/landing/${paramField}`);
      console.log(res, "Response from Source API");
      setShowSeeHow(res.data);
      sessionStorage.setItem("src", encode(JSON.stringify(res.data)));
    } catch (error) {
      setShowSeeHow(null)
      sessionStorage.removeItem("src")
    }
  };

  useLayoutEffect(() => {
    if (paramField) {
      validateSource();
    }else{
      setShowSeeHow(null)
       sessionStorage.removeItem("src")
    }
  }, []);
  const [showContactUs, setShowContactUs] = useState(false);
  const [showSeeHow, setShowSeeHow] = useState({});
  const handleShowContact = () => {
    setShowContactUs(true);
  };

  const handleCloseContactUs = () => {
    setShowContactUs(false);
  };

  async function getFaqQuestionsAnswers() {
    try {
      let obj = {
        sort: 1,
        type: "home",
      };
      const resp = await faqQuestions(obj);

      setHomeQuestions(resp?.item);
      setdumm(resp?.item);

      setCurrentSlide(resp?.item[0]);
      // setCurrentSlide(dummyTexts[activeSlide]);

      //  handleSlideChange()
      setSelectedImage(homepage_content_base_url + resp.item[0].image);
      // }
    } catch (err) {
      console.log(err);
      // alert(err)
    }
  }

  useEffect(() => {
    let list = localStorage.getItem("clinic-lists");
    setClinicsLIst(JSON.parse(list));
    getHomePageData();
    getFaqQuestionsAnswers();
  }, []);
  //const dummyTexts =[]
  const dummyTexts1 = [
    {
      title: "Slide 1 Title",
      description:
        "1 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 2 Title",
      description:
        "2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 3 Title",
      description:
        "3 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 4 Title",
      description:
        "4 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 5 Title",
      description:
        "5 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 6 Title",
      description:
        "6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 7 Title",
      description:
        "7 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
    {
      title: "Slide 8 Title",
      description:
        "8 Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.",
    },
  ];

  const slideImages = [
    "https://images.unsplash.com/photo-1682686581663-179efad3cd2f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1702571202424-84d58143cfcc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1702404275992-929c62049bfe?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTV8fHxlbnwwfHx8fHw%3D",
    "https://images.unsplash.com/photo-1702134599627-6b74bf1c2688?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MjN8fHxlbnwwfHx8fHw%3D",
    "https://images.unsplash.com/photo-1702294186146-ae05ba6e5d02?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MzV8fHxlbnwwfHx8fHw%3D",
    "https://images.unsplash.com/photo-1649445141775-89dd1be635e8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDJ8Ym84alFLVGFFMFl8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1541675154750-0444c7d51e8e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE0fGJvOGpRS1RhRTBZfHxlbnwwfHx8fHw%3D",
    "https://images.unsplash.com/photo-1470252649378-9c29740c9fa8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDI0fGJvOGpRS1RhRTBZfHxlbnwwfHx8fHw%3D",
  ];

  const handleSlideChange = (swiper) => {
    const activeSlide = swiper.activeIndex;
    setCurrentSlide(dummyTexts[activeSlide]);
    console.log("currentSlide", currentSlide);
  };

  let searchBox;

  const onMapLoadStreet = (map) => {
    searchBox = new window.google.maps.places.Autocomplete(
      searchBoxRefStreet.current
    );
    console.log("🚀 ~ onMapLoadStreet ~ searchBox:", searchBox);

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length === 0) {
        // No valid places found
        console.log("Invalid address entered");
        return;
      }

      const loc = places[0].formatted_address;
      console.log(places);

      localStorage.setItem("address", JSON.stringify(loc));
      // Assuming that having at least one place means a valid address
      // You may add more validation based on your requirements
      if (isValidPlace(places[0])) {
        setDetails({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng(),
        });

        const countryComponent = places[0].address_components.find(
          (component) => component.types.includes("country")
        );
        const countryName1 = countryComponent ? countryComponent.long_name : "";

        console.log("Country Name:", countryName1);
        setCountryName(countryName1);
        setValidLocation(true);
      } else {
        console.log("Invalid address entered");
        toast.error("Please enter valid address.");
        setValidLocation(false);
      }
    });
  };

  const isValidPlace = (place) => {
    // Example validation: Check if the place has a valid formatted address

    return (
      place && place.formatted_address && place.formatted_address.trim() !== ""
    );
  };

  const locationHandler = async () => {
    console.log(searchBoxRefStreet.current.value, "search box ref street");

    // Assuming you have a location name in searchBoxRefStreet.current.value
    if (!validLocation) {
      const locationName = searchBoxRefStreet.current.value;

      try {
        // Fetch latitude and longitude from Google Maps Geocoding API
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            locationName
          )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE}`
        );

        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng, formatted_address } =
            data.results[0].geometry.location;
          const country = data.results[0].address_components.find((component) =>
            component.types.includes("country")
          ).long_name;

          // Store data in localStorage or wherever you need
          localStorage.setItem("lat", lat);
          localStorage.setItem("lng", lng);
          localStorage.setItem("country", country);
          localStorage.setItem(
            "address",
            JSON.stringify(data?.results[0].formatted_address)
          );
          if (clinicsLIst) {
            checkClinicsExist(lat, lng, "/recentSearch");
          } else {
            checkClinicsExist(lat, lng, "/questionnair?questionNum=0");
          }
        } else {
          toast.error("Please use precise location");
        }
      } catch (error) {
        toast.error("Please add a valid address");
      }
    } else {
      localStorage.setItem("lat", details?.lat);
      localStorage.setItem("lng", details?.lng);
      localStorage.setItem("country", countryName);
      if (clinicsLIst) {
        checkClinicsExist(details?.lat, details?.lng, "/recentSearch");
      } else {
        checkClinicsExist(
          details?.lat,
          details?.lng,
          "/questionnair?questionNum=0"
        );
      }
    }
  };

  const checkClinicsExist = async (lat, long, route) => {
    const body = {
      coordinates: [long, lat],
    };
    try {
      const resp = await getNearestClinic(body);
      if (resp?.data?.length > 0) {
        navigate(route);
      } else {
        handleShowContact();
      }
    } catch (error) {
      // return true;
      console.log(error);
    }
  };

  // scroll exp

  // const [scrollPosition, setScrollPosition] = useState(0);

  // useEffect(() => {
  //   function handleScroll() {
  //     setScrollPosition(window.pageYOffset);
  //   }

  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // 1
  const [scrollDirection, setScrollDirection] = useState("top"); // State to track scroll direction

  // const [ckickedIndex, setClickedIndex] = useState({
  //   1: 0,
  //   2: 0,
  //   3: 0,
  // });
  // Function to handle scroll events
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target; // Destructure properties from the event target

    // Determine scroll direction based on scrollTop position
    const middlePosition = scrollHeight / 2 - clientHeight / 2;
    console.log(scrollTop, scrollHeight, clientHeight);
    if (scrollTop === 0) {
      setScrollDirection("top");
      // setClickedIndex({
      //   1: 0,
      //   2: 0,
      //   3: 0,
      // });
    } else if (scrollTop + clientHeight === scrollHeight) {
      setScrollDirection("bottom");
      // setClickedIndex({
      //   1: 0,
      //   2: 0,
      //   3: 0,
      // });
    } else if (scrollTop + clientHeight > middlePosition) {
      // In the middle
      setScrollDirection("middle");
      // setClickedIndex({
      //   1: 0,
      //   2: 0,
      //   3: 0,
      // });
    }
  };

  const handleOnclick = (index) => {
    if (index === 1) {
      // setClickedIndex({
      //   1: 1,
      //   2: 0,
      //   3: 0,
      // });
      setScrollDirection("top");
    } else if (index === 2) {
      // setClickedIndex({
      //   1: 0,
      //   2: 2,
      //   3: 0,
      // });
      setScrollDirection("middle");
    } else {
      // setClickedIndex({
      //   1: 0,
      //   2: 0,
      //   3: 3,
      // });
      setScrollDirection("bottom");
    }
  };

  useEffect(() => {
    const user_details = JSON.parse(localStorage.getItem("user_details"));
    if (user_details && user_details?.user_type !== "user") {
      navigate("/dashboard");
    }
  }, []);

  // scroll exp end

  function capitalizeWords(inputString) {
    let words = inputString.split(" ");

    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    let capitalizedString = capitalizedWords.join(" ");

    return capitalizedString;
  }
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude + " " + longitude, "User's address:");
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE}`
            );
            const data = await response.json();
            console.log(data, "User's address:");
            searchBoxRefStreet.current.value =
              data?.results[0]?.formatted_address;
            console.log(searchBoxRefStreet, "searchBoxRefStreet");
          } catch (error) {
            console.error("Error fetching address:", error.message);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleContactUsSubmited = () => {
    if (searchBoxRefStreet.current) {
      searchBoxRefStreet.current.value = "";
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      locationHandler();
    }
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const testimonials =
    homeContentData?.patient_testimonials?.testimonials || [];

  const shouldShowNavigation = testimonials.length > 1;

  if (isLoading) {
    return <AbsoluteLoader loading={true} />;
  }

  const handleFocus = () => {
    const inputElement = searchBoxRefStreet.current;

    // Remove the class if it exists to reset animation
    inputElement.classList.remove("blink-input");

    // Force reflow to reset the animation
    void inputElement.offsetWidth;

    // Add the blinking class
    inputElement.classList.add("blink-input");

    // Set focus after the animation
    setTimeout(() => {
      inputElement.focus();
      inputElement.autoComplete = "off"
    }, 1000); // Match the duration of the animation
  }
  return (
    <>
      <HomeNav />
      {/* <AbsoluteLoader loading={true}/> */}
      {/* <ChatSupport/> */}
      <div className="page-wrap">
        {showSeeHow && <SeeHow data={showSeeHow} search={handleFocus}/>}
        <div className="main_page mobile-padding">
          {/* ----Hero---Page---- */}
          <div className="container">
            <div className="row gap-4 gap-sm-0 hero-container">
              <div className="col-sm-6">
                <div className="hero__body ">
                  <h1 className="hero__title">
                    {homeContentData?.first_section?.title
                      ? homeContentData?.first_section?.title
                      : ""}
                  </h1>
                  <p className="hero__description">
                    {homeContentData?.first_section?.description
                      ? homeContentData?.first_section?.description
                      : ""}
                  </p>
                  <footer className="hero__search" id="animateSearch">
                    <div className="hero__search-box">
                      <Tooltip title="Detect a location">
                        <span
                          className="fa-solid fas fa-location form-control-feedback"
                          onClick={handleCurrentLocation}
                          style={{ cursor: "pointer" }}
                        ></span>
                      </Tooltip>
                      <input
                        className="form-control"
                        placeholder="Your Address"
                        name="school_address"
                        type="textarea"
                        // onChange={(e) => { handleChange(e) }}
                        onFocus={handlePopupOpen}
                        onClick={handlePopupOpen}
                        onKeyPress={handleKeyPress}
                        ref={searchBoxRefStreet} 
                      />

                      {showPopup && (
                        <div className="map-popup">
                          <GoogleMap onLoad={onMapLoadStreet}></GoogleMap>
                        </div>
                      )}
                      <Link
                        to={
                          validLocation && !clinicsLIst
                            ? "/questionnair?questionNum=0"
                            : validLocation && clinicsLIst
                            ? "/recentSearch"
                            : undefined
                        }
                        
                        className="theme_btn d-sm-block d-none"
                        onClick={locationHandler}
                      >
                        {" "}
                        Find A Dentist{" "}
                      </Link>
                    </div>
                    <Link
                      to={
                        validLocation && !clinicsLIst
                          ? "/questionnair?questionNum=0"
                          : validLocation && clinicsLIst
                          ? "/recentSearch"
                          : undefined
                      }
                      className="theme_btn d-sm-none mt-3 d-block"
                      onClick={locationHandler}
                    >
                      {" "}
                      Find A Dentist{" "}
                    </Link>
                  </footer>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="hero__photo">
                  <div className="hero__frame">
                    {homeContentData?.first_section?.side_image && (
                      <div className="imageHeight">
                        <img
                          src={
                            homepage_content_base_url +
                            homeContentData?.first_section?.side_image
                          }
                          onError={(e) => (e.target.src = heroImage)}
                          className=""
                          alt="A dentist Doctor"
                          style={imgStyle}
                        />

                        <span className="dotes short_dot"></span>
                        <span className="dotes big_dots"></span>
                      </div>
                    )}
                    {/* <Skeleton.Avatar size='large' shape='circle' /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----Hero---Page----End */}

        {/* Better--Choice--Section--- */}

        <section className="BetterChoice_container mobile-padding">
          <div className="betterChoice">
            <div className="container">
              <div className="betterChoice__heading_text  ">
                <h2 className="betterChoice__title text-center">
                  {homeContentData?.second_section?.title
                    ? homeContentData?.second_section?.title
                    : ""}
                </h2>
                <p className="betterChoice__heading-desc text-center">
                  {homeContentData?.second_section?.description
                    ? homeContentData?.second_section?.description
                    : ""}{" "}
                </p>
              </div>
              <div className="betterChoice__boxes row row-cols-1 row-cols-md-3 g-4">
                {homeContentData?.card_section &&
                homeContentData?.card_section.length > 0 ? (
                  homeContentData?.card_section.map((ele) => (
                    <div className="col-lg-4 col-md-4 col-xl-4 col-sm-6 col-12 ">
                      <div className="betterChoice__boxe h-100">
                        <div className="betterChoice__icon">
                          {/* <i class="fa-brands fa-searchengin"></i> */}
                          <img
                            src={homepage_content_base_url + ele?.icon}
                            onError={(e) => (e.target.src = seachengine)}
                            className="aboutIc"
                            alt="icon"
                          />
                        </div>
                        <div className="">
                          <h4 className="betterChoice__sub-heading-text">
                            {ele.title ? ele.title : ""}
                          </h4>
                        </div>
                        <div className="betterChoice__sub-heading-desc">
                          {ele.description ? ele.description : ""}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {/* <div className='col-lg-4 col-md-4 col-xl-4 col-sm-6 col-12 '>
                  <div className="betterChoice__boxe  ">
                    <div className='betterChoice__icon'>
                      <i class="fa-regular fa-calendar-days"></i>
                    </div>
                    <div className=''>
                      <h4 className='betterChoice__sub-heading-text'>Schedule</h4>
                    </div>
                    <div className='betterChoice__sub-heading-desc'>All of the dentists we recommend meet your standards. We visit, interview, and background check every.</div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 col-xl-4 col-sm-6 col-12  '>
                  <div className="betterChoice__boxe ">
                    <div className='betterChoice__icon'>
                      <i class="fa-regular fa-map"></i>
                    </div>
                    <div className=''>
                      <h4 className='betterChoice__sub-heading-text'>Visit</h4>
                    </div>
                    <div className='betterChoice__sub-heading-desc'>All of the dentists we recommend meet your standards. We visit, interview, and background check every.</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* Better--Choice--Section---End */}

        {/* ---How-does-it---section */}

        {/* <section className='HowDoesit_container'>
          <div className="HowDoesit">
            <div className="container">
              <div className="HowDoesit__heading_text">
                <h2 className="HowDoesit__title text-center">{homeContentData?.third_section?.title ? homeContentData?.third_section?.title : ''}</h2>
                <p className='HowDoesit__heading-desc text-center'>{homeContentData?.third_section?.description ? homeContentData?.third_section?.description : ''}</p>
              </div>
              <div className="scrollerSwipe">
                <div className='row align-items-center' >
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="info">
                      <h3>{currentSlide?.question}</h3>
                      <p>{currentSlide?.answer}</p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <Swiper
                      effect={'cards'}
                      grabCursor={true}
                      modules={[EffectCards, Mousewheel, Navigation]}
                      className="mySwiper"
                      mousewheel={true}
                      initialSlide={0}
                      onSlideChange={handleSlideChange}
                      navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    >
                      {dummyTexts?.map((imageSrc, index) => (
                        <SwiperSlide key={index}>
                          <img src={homepage_content_base_url + currentSlide?.image} alt={`Slide ${index + 1}`} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="swiperNav">
                      <div className="swiper-button-next"></div>
                      <div className="swiper-button-prev"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Scroll exp start */}
        <section className="HowDoesit_container mobile-padding">
          <div className="HowDoesit">
            <div className="md-container">
              <div className="HowDoesit__heading_text">
                <h2 className="HowDoesit__title text-center">
                  {homeContentData?.third_section?.title
                    ? homeContentData?.third_section?.title
                    : ""}
                </h2>
                <p className="HowDoesit__heading-desc text-center">
                  {homeContentData?.third_section?.description
                    ? homeContentData?.third_section?.description
                    : ""}
                </p>
              </div>
              <div className="d-md-block d-none">
                <div className="row g-4 justify-content-center">
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    {/* <div className="howWorksLeft">
                      <span className='borderShape'></span>
                      <span className='filledShape'></span>
                      <img src={dentistImg} alt="How it works 1" className='howWorksImg' />
                    </div> */}
                    {/* start */}
                    <div
                      id="howWorkImgParent"
                      className="howWorksLeft hidden-scrollbar"
                      style={{
                        height: "415px",
                        overflowY: "scroll",
                        pointerEvents: "none",
                        userSelect: "none",
                        margin: "auto",
                      }}
                      onScroll={handleScroll}
                    >
                      <span className="borderShape"></span>
                      <span className="filledShape"></span>
                      <div id="imagesPrnt" style={{ height: "600px" }}>
                        <div style={{ position: "sticky", top: "0" }}>
                          {/* {ckickedIndex[1] === 1 && (
                            <img
                              src={
                                homeQuestions[0]?.image
                                  ? process.env.REACT_APP_CMS +
                                    homeQuestions[0]?.image
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="1"
                              className="howWorksImg"
                            />
                          )} */}
                          {scrollDirection === "top" && (
                            <img
                              src={
                                homeQuestions[0]?.image
                                  ? process.env.REACT_APP_CMS +
                                    homeQuestions[0]?.image
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="1"
                              className="howWorksImg"
                            />
                          )}
                          {/* {ckickedIndex[2] === 2 && (
                            <img
                              src={
                                homeQuestions[1]?.image
                                  ? process.env.REACT_APP_CMS +
                                    homeQuestions[1]?.image
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="2"
                              className="howWorksImg"
                            />
                          )} */}
                          {scrollDirection === "middle" && (
                            <img
                              src={
                                homeQuestions[1]?.image
                                  ? process.env.REACT_APP_CMS +
                                    homeQuestions[1]?.image
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="2"
                              className="howWorksImg"
                            />
                          )}
                          {/* {ckickedIndex[3] === 3 && (
                            <img
                              src={
                                homeQuestions[1]?.image
                                  ? process.env.REACT_APP_CMS +
                                    homeQuestions[2]?.image
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="3"
                              className="howWorksImg"
                            />
                          )} */}
                          {scrollDirection === "bottom" && (
                            <img
                              src={
                                homeQuestions[1]?.image
                                  ? process.env.REACT_APP_CMS +
                                    homeQuestions[2]?.image
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="3"
                              className="howWorksImg"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end */}
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-7 col-sm-12">
                    <div className="howWorksRtWrap">
                      {/* <div className="howWorksItem">
                        <span className="count">
                          1
                        </span>
                        <div className="itemTxt">
                          <h6>Answer a few simple questions so we get to know you better.</h6>
                          <div className="ansrWrap">
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                          </div>
                        </div>
                      </div>
                      <div className="howWorksItem">
                        <span className="count">
                          2
                        </span>
                        <div className="itemTxt">
                          <h6>Answer a few simple questions so we get to know you better.</h6>
                          <div className="ansrWrap">
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                          </div>
                        </div>
                      </div>
                      <div className="howWorksItem">
                        <span className="count">
                          3
                        </span>
                        <div className="itemTxt">
                          <h6>Answer a few simple questions so we get to know you better.</h6>
                          <div className="ansrWrap">
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                          </div>
                        </div>
                      </div> */}

                      <div
                        id="parentDiv"
                        // style={{ height: "340px", overflowY: "scroll" }}
                        onScroll={handleScroll}
                      >
                        <div id="testdiv1" style={{ height: "600px" }}>
                          <div style={{ position: "sticky", top: "0" }}>
                            <div className="howWorksItem item_1 ">
                              <span
                                onClick={() => handleOnclick(1)}
                                className="accordion_open"
                              ></span>
                              {scrollDirection === "top" && (
                                <span className="count filledCount">1</span>
                              )}
                              {/* {ckickedIndex[1] === 1 && (
                                <span className="count filledCount">1</span>
                              )} */}
                              <span className="count hid">1</span>
                              <div className="itemTxt">
                                <h6>{homeQuestions[0]?.question}</h6>
                                {/* {ckickedIndex[1] === 1 && (
                                  <div className="ansrWrap">
                                    <p>{homeQuestions[0]?.answer}</p>
                                  </div>
                                )} */}
                                {scrollDirection === "top" && (
                                  <div className="ansrWrap">
                                    <p>{homeQuestions[0]?.answer}</p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="howWorksItem item_2">
                              <span
                                onClick={() => handleOnclick(2)}
                                className="accordion_open"
                              ></span>
                              {scrollDirection === "middle" && (
                                <span className="count filledCount">2</span>
                              )}
                              {/* {ckickedIndex[2] === 2 && (
                                <span className="count filledCount">2</span>
                              )} */}
                              <span className="count hid">2</span>
                              <div className="itemTxt">
                                <h6>{homeQuestions[1]?.question}</h6>
                                {/* {ckickedIndex[2] === 2 && (
                                  <div className="ansrWrap">
                                    <p>{homeQuestions[0]?.answer}</p>
                                  </div>
                                )} */}
                                {scrollDirection === "middle" && (
                                  <div className="ansrWrap">
                                    <p>{homeQuestions[1]?.answer}</p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="howWorksItem item_3">
                              <span
                                onClick={() => handleOnclick(3)}
                                className="accordion_open"
                              ></span>
                              {scrollDirection === "bottom" && (
                                <span className="count filledCount">3</span>
                              )}
                              {/* {ckickedIndex[3] === 3 && (
                                <span className="count filledCount">3</span>
                              )} */}
                              <span className="count hid">3</span>
                              <div className="itemTxt">
                                <h6>{homeQuestions[2]?.question}</h6>
                                {/* {ckickedIndex[3] === 3 && (
                                  <div className="ansrWrap">
                                    <p>{homeQuestions[0]?.answer}</p>
                                  </div>
                                )} */}
                                {scrollDirection === "bottom" && (
                                  <div className="ansrWrap">
                                    <p>{homeQuestions[2]?.answer}</p>
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* Display child elements based on scroll direction */}
                            {/* {scrollDirection === "top" && <div>Display on top</div>}
                            {scrollDirection === "bottom" && <div>Display on bottom</div>}
                            {scrollDirection === "" && <div>Display in middle</div>} */}
                          </div>
                        </div>
                      </div>

                      {/* exp start */}
                      {/* <Swiper
                        direction={'vertical'}
                        slidesPerView={3}
                        spaceBetween={30}
                        loop={true}
                        mousewheel={true}
                        modules={[Mousewheel]}
                        className="howWorksSwiper">
                        <SwiperSlide>
                          <div className="howWorksItem Item_1">
                            <span className="count">
                              1
                            </span>
                            <div className="itemTxt">
                              <h6>Answer a few simple questions so we get to know you better.</h6>
                              <div className="ansrWrap">
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="howWorksItem Item_2">
                            <span className="count">
                              2
                            </span>
                            <div className="itemTxt">
                              <h6>Answer a few simple questions so we get to know you better.</h6>
                              <div className="ansrWrap">
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="howWorksItem Item_3">
                            <span className="count">
                              3
                            </span>
                            <div className="itemTxt">
                              <h6>Answer a few simple questions so we get to know you better.</h6>
                              <div className="ansrWrap">
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="howWorksItem Item_3">
                            <span className="count">
                              4
                            </span>
                            <div className="itemTxt">
                              <h6>Answer a few simple questions so we get to know you better.</h6>
                              <div className="ansrWrap">
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed, doloremque nemo. Laboriosam dolores quaerat laborum enim dicta, impedit sapiente obcaecati voluptatum.</p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper> */}
                      {/* exp end */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-md-none d-flex flex-column gap-4 pt-4">
                <div className="row g-0">
                  <div className="col-3">
                    <img
                      src={
                        homeQuestions[0]?.image
                          ? process.env.REACT_APP_CMS + homeQuestions[0]?.image
                          : process.env.REACT_APP_DEFAULT_IMAGE_URL
                      }
                      alt="1"
                      className="img-fluid"
                      style={{
                        height: "180px",
                        objectFit: "contain",
                        objectPosition: "top",
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <div className="">
                      <h5>{homeQuestions[0]?.question}</h5>
                      <div className="ansrWrap">
                        <p
                          style={{
                            backgroundColor: "#FFD9E4",
                            marginLeft: "2px",
                            padding: "0.6em",
                            fontSize: "16px",
                            borderRadius: "1em",
                            borderTopLeftRadius: 0,
                          }}
                        >
                          {homeQuestions[0]?.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-3">
                    <img
                      src={
                        homeQuestions[1]?.image
                          ? process.env.REACT_APP_CMS + homeQuestions[1]?.image
                          : process.env.REACT_APP_DEFAULT_IMAGE_URL
                      }
                      alt="1"
                      className="img-fluid"
                      style={{
                        height: "180px",
                        objectFit: "contain",
                        objectPosition: "top",
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <div className="itemTxt">
                      <h5>{homeQuestions[1]?.question}</h5>
                      <div className="ansrWrap">
                        <p
                          style={{
                            backgroundColor: "#FFD9E4",
                            marginLeft: "2px",
                            padding: "0.6em",
                            fontSize: "16px",
                            borderRadius: "1em",
                            borderTopLeftRadius: 0,
                          }}
                        >
                          {homeQuestions[1]?.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-3">
                    <img
                      src={
                        homeQuestions[2]?.image
                          ? process.env.REACT_APP_CMS + homeQuestions[2]?.image
                          : process.env.REACT_APP_DEFAULT_IMAGE_URL
                      }
                      alt="1"
                      className="img-fluid"
                      style={{
                        height: "180px",
                        objectFit: "contain",
                        objectPosition: "top",
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <div className="itemTxt">
                      <h5>{homeQuestions[2]?.question}</h5>
                      <div className="ansrWrap">
                        <p
                          style={{
                            backgroundColor: "#FFD9E4",
                            marginLeft: "2px",
                            padding: "0.6em",
                            fontSize: "16px",
                            borderRadius: "1em",
                            borderTopLeftRadius: 0,
                          }}
                        >
                          {homeQuestions[2]?.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Scroll exp end */}

        {/* <scetion className="workCont" id="scroll">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="workImageWrap">
                  <img src="https://images.unsplash.com/photo-1700359911531-097e896d03b4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHx8" alt="" />
                </div>
              </div>
              <div className="col-6">
                <div className="stepsWrapper">
                  { homeQuestions && homeQuestions.length > 0 ? homeQuestions.map((ele) => (<div className="step">
                    <h5>{ele?.question ? ele?.question : ''}</h5>
                    <p>{ele?.answer ? ele?.answer : ''}</p>
                  </div>)) : <></>}
                 <div className="step">
                    <h5>Answer a few simple questions so we get to know you better.</h5>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, maxime tempore. Quibusdam nostrum doloremque nobis cumque sit voluptas minus inventore aspernatur dolorum cum neque dolore odit rerum, voluptatibus voluptate molestias!</p>
                  </div>
                  <div className="step">
                    <h5>Answer a few simple questions so we get to know you better.</h5>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, maxime tempore. Quibusdam nostrum doloremque nobis cumque sit voluptas minus inventore aspernatur dolorum cum neque dolore odit rerum, voluptatibus voluptate molestias!</p>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </scetion> */}
        {/* ---How-does-it---section--End*/}

        {/* ---Testimonials---section */}

        <section className="Testimonials_container mobile-padding">
          <div className="HowDoesit">
            <div className="container">
              <div className="HowDoesit__heading_text">
                <h2 className="HowDoesit__title text-center">
                  {homeContentData?.patient_testimonials &&
                  homeContentData?.patient_testimonials?.title
                    ? homeContentData?.patient_testimonials?.title
                    : ""}
                </h2>
                <p className="HowDoesit__heading-desc text-center">
                  {homeContentData?.patient_testimonials &&
                  homeContentData?.patient_testimonials?.description
                    ? homeContentData?.patient_testimonials?.description
                    : ""}
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                  <div className="testimonials">
                    <Swiper
                      modules={[Navigation]}
                      navigation={{
                        prevEl: shouldShowNavigation ? prevRef.current : null,
                        nextEl: shouldShowNavigation ? nextRef.current : null,
                      }}
                      onInit={(swiper) => {
                        if (shouldShowNavigation) {
                          swiper.params.navigation.prevEl = prevRef.current;
                          swiper.params.navigation.nextEl = nextRef.current;
                          swiper.navigation.init();
                          swiper.navigation.update();
                        }
                      }}
                      spaceBetween={50}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      // navigation={
                      //   homeContentData?.patient_testimonials &&
                      //     homeContentData?.patient_testimonials?.testimonials
                      //       .length > 1
                      //     ? true
                      //     : false
                      // }
                    >
                      {homeContentData?.patient_testimonials &&
                      homeContentData?.patient_testimonials?.testimonials
                        .length > 0 ? (
                        homeContentData?.patient_testimonials?.testimonials.map(
                          (ele) => (
                            <SwiperSlide>
                              <div className="testimonial-item">
                                <div className="testimonial-img-wrap">
                                  <img
                                    src={
                                      ele?.image && ele?.image !== ""
                                        ? homepage_content_base_url + ele?.image
                                        : ""
                                    }
                                    className="testimonial-img"
                                    alt=""
                                  />
                                </div>
                                <div className="testimonial-txt">
                                  <p className="testimonial-desc">
                                    {ele?.description &&
                                    ele?.description !== null ? (
                                      ele?.description
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                  <h4 className="testimonial-name">
                                    {" "}
                                    {ele?.name && ele?.name !== null ? (
                                      capitalizeWords(ele?.name)
                                    ) : (
                                      <></>
                                    )}
                                  </h4>
                                  {/* <p>--- Dentist Specialist</p> */}
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </Swiper>
                    <div className="custom-navigation">
                      <button ref={prevRef} className="custom-prev-button">
                        <i class="fa-solid fa-chevron-left"></i>
                      </button>
                      <button ref={nextRef} className="custom-next-button">
                        <i class="fa-solid fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ---Testimonials---section--End*/}

        {/* ---Dentist-doctor--- */}

        <section className="dentists_container pb-0 mobile-padding">
          <div className="dentist">
            <div className="container">
              <div className="row g-4 justify-content-center">
                <div className="col-lg-6 col-xl-6 col-sm-6 col-12 col-md-6">
                  <div className="dentist__body">
                    <h2 className="dentist__title ">
                      {homeContentData?.last_section?.title
                        ? homeContentData?.last_section?.title
                        : ""}
                      <span className="hello_d">
                        {" "}
                        {/* <img
                          src={handicon}
                          className="ms-2 animate__animated animate__delay-1s animate__headShake"
                          alt="hand"
                        /> */}
                        <WavingHand
                          style={{ fontSize: "50px" }}
                          fontSize="large"
                          className="ms-2 animate_animated animatedelay-1s animate_headShake wave-color"
                        />
                      </span>
                    </h2>
                    <p className="dentist__description">
                      {homeContentData?.last_section?.description
                        ? homeContentData?.last_section?.description
                        : ""}
                    </p>
                    <div className="sign_up">
                      {token === null ? (
                        // to="/find-dentist"
                        <Link to="/find-dentist" className="theme_btn ">
                          Sign Up Today
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="dottend_image">
                      {/* <img src={dottedImage} className="" alt="dotted" /> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-6 col-12 col-md-6">
                  <div className="dentist__photo">
                    <img
                      src={
                        homepage_content_base_url +
                        homeContentData?.last_section?.side_image
                      }
                      onError={(e) => (e.target.src = dentist)}
                      className=""
                      alt="dentist"
                    />
                    <div className="dottend_image">
                      {/* <img src={dottedImage} className="" alt="dotted" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactForm
          show={showContactUs}
          handleClose={handleCloseContactUs}
          handleSubmit={handleContactUsSubmited}
        />

        {/* ---Dentist-doctor---End */}

        <Footer />
      </div>
    </>
  );
};

export default Homepage;
