import "./App.css";
import AllRoute from "./AllRoutes/AllRoute";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ChatSupport from "./components/ChatSupport/ChatSupport";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBar from 'react-top-loading-bar';
import Loader from "./components/Loader/Loader";
import { useLoadScript } from "@react-google-maps/api";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import loadTikTokPixel from "./lib/loadTikTokPixel";
const libraries = ["places"];


function App() {

  const location = useLocation();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const loading = useSelector((state) => state.loader.loading);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });


  useEffect(() => {
    // Load TikTok Pixel when the app mounts
    loadTikTokPixel();
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'ViewContent');
    }

    // Track page changes
    if (window.ttq) {
      window.ttq.page()
    }
  }, [location.pathname]);
  return (
    <div className="App">
      <Helmet>
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '528200516228089'); 
          fbq('track', 'PageView');
          `}
        </script>
        <script>
          {`
            !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
            var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
            ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            ttq.load('CTKU0CBC77UFAAV31Q60');
            ttq.page();
            }(window, document, 'ttq');
          `}
        </script>
      </Helmet>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
      >
      <LoadingBar color="#1E90FF"
       progress={loading ? 100 : 0}
      
        />
        {/* <Loader loading={loading}/> */}
        <ToastContainer />
        <AllRoute />
        {/* {
          isAuthenticated && 
        <ChatSupport/>
           
        } */}
      </GoogleOAuthProvider>

    </div>
  );
}

export default App
