import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import user from "../../../src/Assets/user1.jpg";
import user from "../../../src/Assets/noUserImage.png";

import { useFormik } from "formik";
import * as Yup from "yup";
import { errorNotification, imageUpload, makeHttpRequest, successNotification } from "../../utils/httpServices/httpService";


const AddStaff = ({ show, handleClose, clinic_id, staffDetails, getStaffDetails }) => {



  const handleAddStaff = async (values) => {
    const body = { ...values, clinic_id: clinic_id }
    try {
      const resp = await makeHttpRequest('post', "users/addStaff", body)
      getStaffDetails(clinic_id)
      successNotification("Staff Added Successfully")
      handleClose()
    } catch (error) {
      console.log(error)
      errorNotification(error?.response?.data?.msg)
    }
  }

  const handleEditStaff = async (values) => {
    try {
      const resp = await makeHttpRequest('patch', `users/editStaff/${values?._id}`, values)
      successNotification("Staff Details Edited Successfully")
      getStaffDetails(clinic_id)
      handleClose()
    } catch (error) {
      console.log(error)
    }

  }


  const validationSchema = Yup.object({
    full_name: Yup.string().required("Name is required"),
    email: Yup.string().email("Please enter a valid email address").required("Email is required"),
    phone_number: Yup.string().required("Phone number is required"),

  })

  useEffect(() => {
    if (staffDetails) {
      formik.setValues({
        profile_image: staffDetails.profile_image || "",
        full_name: staffDetails.full_name || "",
        email: staffDetails.email || "",
        phone_number: staffDetails.phone_number || "",
        _id: staffDetails._id || ""
      });
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      profile_image: "",
      full_name: "",
      email: "",
      phone_number: ""
    },
    validationSchema: validationSchema,
    onSubmit: staffDetails ? handleEditStaff : handleAddStaff

  });


  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if ((file.type.split('/').includes('gif') ||
        file.type.split('/').includes('jpg') ||
        file.type.split('/').includes('jpeg') ||
        file.type.split('/').includes('png'))) {
        if (file.size <= 10000000) {
          const data = new FormData;
          data.append("path", "userProfile");
          data.append("media", file);
          try {
            const resp = await imageUpload(data);
            console.log("upload provider profile image response", resp);
            if (resp?.code === 200) {
              successNotification("Image uplaoded Successfully");
              formik.setFieldValue('profile_image', (process.env.REACT_APP_USER_PROFILE_IMAGE + resp.path));
            }
          }
          catch (error) {
            console.log("upload profile image error", error)
          }
        }
        else {
          errorNotification("Image size is greater than 2MB")
        }
      }
      else {
        errorNotification("Please upload image in supported format (PNG,JPG,GIF)")
      }
    }
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Modal show={show} onHide={handleClose}  centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <div className="staff_ad ">
              <div className="row justify-content-center">
                <div className="card p-2 border-0 mb-0 w-100">
                  <div className="row">
                    <div className="col-md-4 mb-4">
                      <div className="d-flex flex-column h-100 w-100 gap-2">
                        <img
                          src={formik.values.profile_image || user}
                          alt="staff user"
                          className="img-fluid"
                          style={{ width: "180px", borderRadius: "10px" }}
                        />
                        <label htmlFor="upload" className="uploadButton">
                          <input type="file" id="upload" accept="image/*"
                            onChange={handleImageUpload}
                          />

                          choose file
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div class="mb-3">
                        <label for="Name" class="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="Name"
                          placeholder="Name"
                          {...formik.getFieldProps("full_name")}
                        />
                        {formik.touched.full_name && formik.errors.full_name ? (
                          <p className="form-error">{formik.errors.full_name}</p>
                        ) : null}
                      </div>
                      <div class="mb-3">
                        <label for="Email" class="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="Email"
                          placeholder="name@example.com"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p className="form-error">{formik.errors.email}</p>
                        ) : null}
                      </div>
                      <div class="mb-3">
                        <label for="number" class="form-label">
                          Number
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="mblnumber"
                          placeholder=""
                          {...formik.getFieldProps("phone_number")}
                        />
                        {formik.touched.phone_number && formik.errors.phone_number ? (
                          <p className="form-error">{formik.errors.phone_number}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              formik.handleSubmit();
            }}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default AddStaff;
