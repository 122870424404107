import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import companyLogo from "../../Assets/Logos/logo1.png";
import logo1 from "../../Assets/Logos/company-logo.png";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import ContactForm from "../ContactForm/ContactForm";
import { getNearestClinic } from "../../utils/httpServices/httpService";

export default function Navbar() {
  const navigate = useNavigate();
  const libraries = ["places"];
  const searchBoxRefStreet = useRef(null);
  const [validLocation, setValidLocation] = useState(false);

  function displayNavbar() {
    const navbar = document.getElementById("HoneNavtwo");
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
      navbar.classList.add("hide");
    } else {
      navbar.classList.remove("hide");
      navbar.classList.add("show");
    }
  }

  function displayNavbarScroll() {
    const navbar = document.getElementById("HoneNavScrol");
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
      navbar.classList.add("hide");
    } else {
      navbar.classList.remove("hide");
      navbar.classList.add("show");
    }
  }

  const [details, setDetails] = useState({
    lat: 0,
    lng: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  //   libraries,
  // });
  const [clinicsLIst, setClinicsLIst] = useState([]);

  const [countryName, setCountryName] = useState("");
  // const [scrolled, setScrolled] = useState(false);
  // let account_type = null;

  const [scrolled, setScrolled] = useState(false);
  let account_type = null;
  const token = localStorage.getItem("token");
  const user_data = localStorage.getItem("user_details");
  if (user_data !== null) {
    const parsedData = JSON.parse(user_data);
    account_type = parsedData.user_type;
  }

  const [showContactUs , setShowContactUs] = useState(false)

  const handleShowContact = ()=>{
    setShowContactUs(true)
  }

  const handleCloseContactUs = ()=>{
    setShowContactUs(false)

  }

  useEffect(() => {
    // Add a scroll event listener
    let list = localStorage.getItem("clinic-lists");
    setClinicsLIst(JSON.parse(list));

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let navdefault = document.getElementById("navbardefault3");
    let navabrOnscroll = document.getElementById("navabrOnscroll3");
    // let animNone =  document.getElementById('animateSearch')
    // let animActive = document.getElementById('navbarSupportedContent2')

    // Check the scroll position and update the 'scrolled' state
    if (window.scrollY > 100) {
      setScrolled(true);
      navdefault.style.display = "none";
      navabrOnscroll.style.display = "block";
      // animNone.style.display = "none"
      // animActive.classList.add('animateSearchNew')
    } else {
      setScrolled(false);
      navdefault.style.display = "block";
      navabrOnscroll.style.display = "none";
      // animNone.style.display = "block"
      // animActive.classList.remove('animateSearchNew')
    }
  };

  const onMapLoadStreet = (map) => {
    const searchBox = new window.google.maps.places.SearchBox(
      searchBoxRefStreet.current
    );

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        // No valid places found
        console.log("Invalid address entered");
        return;
      }

      const loc = places[0].formatted_address;
      console.log(places);

      localStorage.setItem("address", JSON.stringify(loc));

      // Extract country name from address components
      const countryComponent = places[0].address_components.find((component) =>
        component.types.includes("country")
      );
      const countryName1 = countryComponent ? countryComponent.long_name : "";

      console.log("Country Name:", countryName1);
      setCountryName(countryName1);
      // Assuming that having at least one place means a valid address
      // You may add more validation based on your requirements
      if (isValidPlace(places[0])) {
        setDetails({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng(),
        });
        setValidLocation(true);
      } else {
        console.log("Invalid address entered");
        toast.error("Please enter valid address.");
        setValidLocation(false);
      }
    });
  };
  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const isValidPlace = (place) => {
    // Example validation: Check if the place has a valid formatted address

    return (
      place && place.formatted_address && place.formatted_address.trim() !== ""
    );
  };

  const locationHandler = async () => {
    console.log(searchBoxRefStreet.current.value, "search box ref street");

    // Assuming you have a location name in searchBoxRefStreet.current.value
    if (!validLocation) {
      const locationName = searchBoxRefStreet.current.value;

      try {
        // Fetch latitude and longitude from Google Maps Geocoding API
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            locationName
          )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE}`
        );

        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng, formatted_address } =
            data.results[0].geometry.location;
          const country = data.results[0].address_components.find((component) =>
            component.types.includes("country")
          ).long_name;

          console.log("Latitude:", lat);
          console.log("Longitude:", lng);
          console.log("Country:", country);
          console.log("Formatted Address:", formatted_address);

          // Store data in localStorage or wherever you need
          localStorage.setItem("lat", lat);
          localStorage.setItem("lng", lng);
          localStorage.setItem("country", country);
          localStorage.setItem(
            "address",
            JSON.stringify(data?.results[0].formatted_address)
          );
          if (clinicsLIst) {
            checkClinicsExist(lat ,lng ,"/recentSearch")
          } else {
            checkClinicsExist(lat ,lng ,"/questionnair?questionNum=0")
          }
        } else {
          toast.error("Please use precise location");
        }
      } catch (error) {
        toast.error("Please add a valid address");
      }
    } else {
      localStorage.setItem("lat", details?.lat);
      localStorage.setItem("lng", details?.lng);
      localStorage.setItem("country", countryName);
      if (clinicsLIst) {
        checkClinicsExist(details?.lat ,details?.lng ,"/recentSearch")
      } else {
        checkClinicsExist(details?.lat ,details?.lng ,"/questionnair?questionNum=0")
      }
    }
  };
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude + " " + longitude, "User's address:");
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE}`
            );
            const data = await response.json();
            console.log(data, "User's address:");
            searchBoxRefStreet.current.value =
              data?.results[0]?.formatted_address;
            console.log(searchBoxRefStreet, "searchBoxRefStreet");
          } catch (error) {
            console.error("Error fetching address:", error.message);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const checkClinicsExist= async(lat , long, route)=>{
    const body = {
      coordinates: [long ,lat],
    }
      try {
         const resp = await getNearestClinic(body)
          if(resp?.data?.length>0){

            navigate(route);
          }else{
            handleShowContact()
          }
          
      } catch (error) {
        // return true;
        console.log(error)
      }
  }

  const handleContactUsSubmited =  ()=>{
    if (searchBoxRefStreet.current) {
      searchBoxRefStreet.current.value = '';
    }
  }

  const handleKeyPress = (event) => {

    if (event.key === 'Enter') {
      locationHandler()
    }
  };

  return (
    <>
      <nav
        id="navbardefault3"
        className={`navbar patient-navbar-two navbar-expand-lg shadow bg-black fixed-top ${
          scrolled ? "navbar-scrolled" : ""
        }`}
      >
        <div className="container">
          <Link className="navbar-brand flex-grow-1" to="/">
            <img
              src={companyLogo}
              alt="Logo"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button class="navbar-toggler" onClick={displayNavbar}>
            <i class="fa-solid fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="HoneNavtwo">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link text-white" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white"
                  to={`${process.env.REACT_APP_DENTIST_BLOG_LINK}`}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/support">
                  Support
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/find-dentist">
                  For Dentists
                </Link>
              </li>
            </ul>

            {token === null ? (
              <form className="d-flex" role="search">
                <Link
                  to="/login"
                  class="theme_btn text-decoration-none"
                  // type="button"
                >
                  Log in
                </Link>
              </form>
            ) : token !== null && account_type === "user" ? (
              <Link
                to="/user-profile/tab1"
                class="theme_btn text-decoration-none"
                // type="button"
              >
                Go to User Profile
              </Link>
            ) : (
              <Link
                to="/dentistProfile"
                class="theme_btn text-decoration-none"
                // type="button"
              >
                Go to Dentist Profile
              </Link>
            )}
          </div>
        </div>
      </nav>

      <nav
        id="navabrOnscroll3"
        className={`navbar patient-navbar navbar-expand-lg shadow bg-black fixed-top ${
          scrolled ? "navbar-scrolled" : ""
        }`}
      >
        <div className="container">
          <Link className="navbar-brand flex-grow-1" to="/">
            <img
              src={logo1}
              alt="Logo"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button class="navbar-toggler" onClick={displayNavbarScroll}>
            <i class="fa-solid fa-bars"></i>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end "
            id="HoneNavScrol"
          >
            <div className="hero__search-box pt-0" id="navSearchAnimate1">
              <Tooltip title="Detect a location">
                <span
                  className="fa-solid fas fa-location form-control-feedback"
                  onClick={handleCurrentLocation}
                  style={{ cursor: "pointer" }}
                ></span>
              </Tooltip>
              <input
                className="form-control"
                placeholder="Your Address"
                name="school_address"
                type="textarea"
                // onChange={(e) => { handleChange(e) }}
                onFocus={handlePopupOpen}
                onClick={handlePopupOpen}
                onKeyPress={handleKeyPress}
                ref={searchBoxRefStreet}
              />

              {showPopup && (
                <div className="map-popup">
                  <GoogleMap
                    onLoad={onMapLoadStreet}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                  ></GoogleMap>
                </div>
              )}
              <Link
                // to={`${showPopup && validLocation && clinicsLIst !== null ? "/recentSearch" : "/questionnair"}`}
                to={
                  validLocation && !clinicsLIst
                    ? "/questionnair?questionNum=0"
                    : validLocation && clinicsLIst
                    ? "/recentSearch"
                    : undefined
                }
                className="theme_btn "
                // type="button"
                onClick={locationHandler}
              >
                {" "}
                Find A Dentist{" "}
              </Link>
            </div>
          </div>
        </div>
        <ContactForm
          show={showContactUs}
          handleClose={handleCloseContactUs}
          handleSubmit={handleContactUsSubmited}
        />
      </nav>
    </>
  );
}
