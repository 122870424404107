import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  bookedSlot,
  bookedSlots,
  getClinicData,
  getClinicsData,
  getDashboard_approveOrRejectAppontment,
  getDentistSlotForSuggestion,
  getProviderList,
  getProviderLists,
  getSlots,
  getSlott,
  makeHttpRequest,
  reseduleAppointment,
} from "../../utils/httpServices/httpService";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { ContactlessOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Avatar } from "antd";
import avatar from "../../Assets/avtar_img.jpeg";

export const ResheduleApp = ({ show, handleClose, id, appointmentDetail ,handleConfirmInParent}) => {
  console.log("🚀 ~ ResheduleApp ~ appointmentDetail:", appointmentDetail);

  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;


  const [providerList, setProvderList] = useState([]);

  const [selectedDentist, setSelectedDentist] = useState("");
  console.log("🚀 ~ ResheduleApp ~ selectedDentist:", selectedDentist)

  const [appointmentDate, setAppointmentDate] = useState({});
  console.log("🚀 ~ ResheduleApp ~ appointmentDate:", appointmentDate);

  const [selectedDate, setSelectedDate] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [workingHours, setWorkingHours] = useState([]);

  const [tickedSlot, setTickedSlot] = useState("");

  const [tickedDate, setTickedDate] = useState("");

  const [selectedSlots, setSelectedSlots] = useState([]);
  console.log("🚀 ~ ResheduleApp ~ selectedSlots:", selectedSlots);

   

  const [selectedSlotsForShowSelections, setSelectedSlotsForShowSelections] =
    useState([]);

    const [dentistProfile , setDentistProfile] = useState({})
  const [ clinicDetails , setClinicDetails] = useState({})

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function formatCustomDate(date) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek}${dayOfMonth} ${month} ${year}`;
  }

  const formik1 = useFormik({
    initialValues: {
      Sunday: 7,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    },
  });

  const formik = useFormik({
    initialValues: {
      Sunday: "",
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: "",
      Saturday: "",
    },
  });
  console.log("🚀 ~ ResheduleApp ~ formik:", formik?.values)

  const formik2 = useFormik({
    //For collecting the slots from api and display on UI
    initialValues: {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    },
  });

  console.log("------formik2 values-----", formik2.values);

  const getProvidersList = async (branch_id) => {
    try {
      const resp = await getProviderLists(branch_id);
      setProvderList(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getClincicDetails = async (branch_id) => {
    try {
      const resp = await getClinicsData(branch_id);
      setClinicDetails(resp?.data?.[0])
      setWorkingHours(resp?.data[0]?.working_hours);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (appointmentDetail?.branch_id) {
      getProvidersList(appointmentDetail?.branch_id);
      getClincicDetails(appointmentDetail?.branch_id);
    }
  }, []);

  //set default seleclted dentist?.appointmentDetail
  useEffect(() => {
    if (appointmentDetail?.dentist_id && appointmentDetail?.booked_on_dentist) {
      setSelectedDentist(appointmentDetail?.dentist_id);
    }
    formatDateWithDayName(appointmentDetail?.start_time);
  }, [appointmentDetail]);

  useEffect(() => {
    if (selectedDentist !== "") {
      getSlot(selectedDentist);
    } else {
      // getSlotsForAnyDentistSlot();
      getClinicSlotsAcctoDentists(startDate,endDate);
    }
  }, [selectedDentist, startDate, endDate]);

  function getSlotsForAnyDentistSlot() {
    console.log("workingHours", workingHours);
    console.log(formik?.values); // Use optional chaining here
    try {
      for (let index = 0; index < workingHours?.length; index++) {
        var start = new Date(workingHours?.[index]?.start_time); // Use optional chaining here
        var end = new Date(workingHours?.[index]?.end_time); // Use optional chaining here
        console.log(start, end);

        const day = workingHours?.[index]?.day; // Use optional chaining here
        const dateRange = formik?.values; // Use optional chaining here
        const date = dateRange?.[day]; // Use optional chaining here
        console.log("date", date);
        const dateObject = parseCustomDateString1(date);
        console.log("dateObject", dateObject);

        start.setDate(dateObject?.date); // Use optional chaining here
        start.setMonth(dateObject?.month); // Use optional chaining here
        start.setFullYear(dateObject?.year); // Use optional chaining here

        // Check if end date is in next day
        if (end.getDate() !== start?.getDate()) {
          // Use dateObject with next day for end
          end.setDate(dateObject?.date + 1);
          end.setMonth(dateObject?.month);
          end.setFullYear(dateObject?.year);
        } else {
          // Use same dateObject for end
          end.setDate(dateObject?.date);
          end.setMonth(dateObject?.month);
          end.setFullYear(dateObject?.year);
        }

        console.log(start, end);

        const slot = [];

        while (start.getTime() + 60 * 60 * 1000 <= end.getTime()) {
          slot.push({
            start: start,
            end: new Date(start.getTime() + 60 * 60 * 1000),
            status: "available",
          });

          start = new Date(start.getTime() + 60 * 60 * 1000);
        }

        console.log("slot in getSlotsForAnyDentistSlot", slot);
        const formattedSlots = slot?.map((val1) => {
          const start1 = new Date(val1?.start)
            ?.toLocaleString()
            ?.split(", ")[1];
          const end1 = new Date(val1?.end)?.toLocaleString()?.split(", ")[1];
         

          const status = "available";
          return localCon1(start1, end1, status);
        });

        const updatedValues = [...formattedSlots];
        formik2.setFieldValue(day, updatedValues);
      }
    } catch (err) {
      console.log(err);
    }
  }


  const getClinicSlotsAcctoDentists = async (startDate ,endDate)=> {
    if(!startDate){
      return ;
    }

    const dayOfWeek =  "Monday";

    const localStartTime = new Date(startDate)

    const DateStringUtC = new Date(localStartTime.setHours(0,0,0,0)).toISOString()

    const localDate =  localStartTime.toLocaleDateString('zh-Hans-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');

    console.log("new payload",{localStartTime,DateStringUtC,localDate,startDate})
    
    const params = {
      day: dayOfWeek,
      date : DateStringUtC,
      dateString: localDate
      
    }

   

     try {
      const resp = await makeHttpRequest("get", `users/all-dentist-clinic-slots/${appointmentDetail?.branch_id}`,null,params)
      const clinicSlots = resp?.data
      clinicSlots.forEach(dayObj=> {
        const formattedSlots = dayObj?.slots?.map((slot) => {
          const currentTime = new Date().getTime();
          const slotStart = new Date(slot?.start).getTime();
          const start1 = new Date(slot?.start)
            ?.toLocaleString('en-US', {hour12: false})
            ?.split(", ")[1];
          const end1 = new Date(slot?.end)?.toLocaleString('en-US', {hour12: false})?.split(", ")[1];
         
  
          const status = slot?.isMatchedSlot || slotStart < currentTime ? "selected": "available"
          return localCon1(start1, end1, status);
        });
        const updatedValues = [...formattedSlots];
        formik2.setFieldValue(dayObj?.day, updatedValues);
      })
     } catch (error) {
      console.log("error in getting all slots:",error)
     }
  }


  const getSlot = async (providerId) => {
    console.log("check here", {startDate, endDate})
  

    try {
      if (providerId && startDate && endDate) {
        let resp = {};
        let resp1 = [];
        let resp2 = [];

        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem("user_details");
        let v = JSON.parse(JSON.stringify(user_data));

        let start = new Date(startDate);

        start.setUTCDate(start.getUTCDate() - 2);
        let adjustedStartDate = start.toISOString();

        let end = new Date(endDate);

        end.setUTCDate(end.getUTCDate() + 2);
        let adjustedEndDate = end.toISOString();

        if (
          token != null &&
          user_data != null &&
          user_data?.user_type === "user"
        ) {
          resp = await getSlots(providerId);
          // console.log(resp?.data);
          // setImage1(resp?.data?.profile_image);
          // setName(resp?.data?.full_name);
          // setAddress(resp?.data?.address);
          const params = {
            dentist_id: providerId,
            start_date: adjustedStartDate,
            end_date: adjustedEndDate,
          };

          resp1 = await bookedSlot(params);
          // resp2 = await disabledSlot(id)
        } else {
          // resp = await getSlott(providerId);
          resp = await getDentistSlotForSuggestion(providerId);

          // console.log(resp?.data);
          // setImage1(resp?.data?.profile_image);
          // setName(resp?.data?.full_name);
          // setAddress(resp?.data?.address);
          const params = {
            dentist_id: providerId,
            start_date: adjustedStartDate,
            end_date: adjustedEndDate,
          };

          resp1 = await bookedSlots(params);
          // resp2 = await disabledSlots(id)
        }
        // console.log(resp1)
        // const[resp, resp1] = Promise.all([getSlots(providerId), bookedSlots(params)])

        const dateRange = formik?.values;

        const modifiedSlotWithDate = resp?.data?.slots?.map((ele) => {
          const day = ele?.day;
          const date = dateRange[day];

          const dateObject = parseCustomDateString1(date);

          const newSlot = ele?.slot?.map((elem) => {
            console.log(elem?.start);
            console.log(dateObject);
            const start = new Date(elem?.start);
            start.setDate(dateObject.date);
            start.setMonth(dateObject.month);
            start.setFullYear(dateObject.year);

            const end = new Date(elem?.end);
            end.setDate(dateObject.date);
            end.setMonth(dateObject.month);
            end.setFullYear(dateObject.year);

            return { start: start.toISOString(), end: end.toISOString() };
          });

          return { day: day, slot: newSlot };
        });

        console.log("resp?.data?.slots", resp?.data?.slots);
        console.log("modifiedSlotWithDate", modifiedSlotWithDate);

        console.log("resp1----------booked----->", resp1, resp);
        modifiedSlotWithDate.forEach((val) => {
          console.log(val);
          const formattedSlots = val?.slot?.map((val1) => {
            const start1 = new Date(val1?.start)
              ?.toLocaleString()
              ?.split(", ")[1];
            const end1 = new Date(val1?.end)?.toLocaleString()?.split(", ")[1];
            console.log(start1, end1);

            const checker = isSlotBooked(val1, val?.day, resp1?.data);
            if (checker) {
              const status = "selected";
              return localCon1(start1, end1, status);
            } else {
              const status = "available";
              return localCon1(start1, end1, status);
            }
          });

          console.log(formattedSlots);

          const updatedValues = [...formattedSlots];
          console.log(updatedValues);

          // // Set the concatenated values for the current day
          formik2.setFieldValue(val?.day, updatedValues);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function isSlotBooked(slot, day, bookedSlot) {
    for (let index = 0; index < bookedSlot.length; index++) {
        const element = bookedSlot[index];
        const bookedStart = new Date(element?.start).getTime();
        const bookedEnd = new Date(element?.end).getTime();
        const slotStart = new Date(slot?.start).getTime();
        const slotEnd = new Date(slot?.end).getTime();
        const currentTime = new Date().getTime();

        if (slotStart < currentTime) {
          return true; // Slot start time is in the past, slot is booked
      }

        if (day === daysOfWeek[new Date(element?.start).getDay()]) {
            // Check for overlap
            if (!(slotEnd <= bookedStart || slotStart >= bookedEnd)) {
                return true; // There is overlap, slot is booked
            }
        }
    }
    return false; // No overlap found, slot is not booked
}


  // function isSlotBooked(slot, day, bookedSlot) {
  //   var result = false;
  //   console.log("slot", slot);
  //   for (let index = 0; index < bookedSlot.length; index++) {
  //     const element = bookedSlot[index];

  //     const dateObject = new Date(element?.start);
  //     console.log("dateObject", dateObject);
  //     const dayOfWeek = dateObject.getDay();
  //     console.log("dayOfWeek", dayOfWeek);

  //     let corrres_day = daysOfWeek[dayOfWeek];
  
  //     if (
  //       corrres_day === day &&
  //       new Date(element?.start).getTime() ===
  //         new Date(slot?.start).getTime() &&
  //       new Date(element?.end).getTime() === new Date(slot?.end).getTime()
  //     ) {
  //       // console.log("truekjfksd")
  //       result = true;
  //       break;
  //     }
  //   }

  //   return result;
  // }

  function formatDateWithDayName(dateString) {
    const inputDate = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = inputDate.toLocaleDateString("en-US", options);
    const dayName = new Intl.DateTimeFormat("en-US", {
      weekday: "long",
    }).format(inputDate);

    setAppointmentDate({ date: formattedDate, day: dayName });
  }

  function timeConversionForFilter(date) {
    const startInputTime = new Date(date);
    startInputTime.setHours(0, 0, 0, 0);
    return startInputTime.toISOString();
  }

  const incrementWeek = (direction) => {
    if (!selectedDate) {
      console.log("error : date is not select");
      return;
    }
    const dateString = new Date(selectedDate);

    const incrementDays = direction === "next" ? 7 : -7;

    const newDate = new Date(dateString);
    newDate.setDate(dateString.getDate() + incrementDays);

    if (direction !== "next") {
      const currentWeekStart = new Date();
      currentWeekStart.setDate(
        currentWeekStart.getDate() - currentWeekStart.getDay()
      );
      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

      if (newDate < currentWeekStart) {
        console.log("error: Week exceeds the current week");
        return;
      }
    }

    dateHandler1(null, "arrowClick", newDate);
  };

  const dateHandler1 = (e, mode, newDateWithOffset) => {
    let originalDate = "";
    if (mode === "arrowClick") {
      originalDate = newDateWithOffset;
      setSelectedDate(newDateWithOffset);
    } else {
      originalDate = new Date(e.target.value);
      setSelectedDate(e.target.value);
      console.log("originalDate", originalDate);
    }

    getStartDate(originalDate)
    const dayOfWeek1 = originalDate.getDay();
    console.log("dayOfWeek1 dateHandler1", dayOfWeek1);

    const dayName = daysOfWeek[dayOfWeek1];
    const behindDays = formik1.values[dayName];

    console.log("behindDays dateHandler1", behindDays);
    const beyondDays = 7 - parseInt(formik1.values[dayName]);
    console.log("beyondDays", beyondDays);

    const startingDate = new Date(originalDate);

    startingDate.setDate(originalDate.getDate() - behindDays);
    const utcConve = startingDate;
   
    // setStartDate(timeConversionForFilter(utcConve));

    const EndDate = new Date(originalDate);
    EndDate.setDate(originalDate.getDate() + beyondDays);
    const utcConver = EndDate;

    setEndDate(timeConversionForFilter(utcConver));

    let currentDate = new Date(startingDate);
    let i = 0;
    while (currentDate <= EndDate) {
      formik.setFieldValue(
        daysOfWeek[currentDate.getDay()],
        formatCustomDate(currentDate)
      );
      console.log(formatCustomDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Increment to the next day
    }
  };


  const getStartDate = (originalDate) => {
  
    const dayOfWeek1 = originalDate.getDay();

  
    // Adjusting behindDays to consider Monday as the start date
    const behindDays = (dayOfWeek1 === 1) ? 0 : (dayOfWeek1 > 1) ? dayOfWeek1 - 1 : 6; // Monday as start date
  
    console.log("behindDays dateHandler1", behindDays);
  
    const startingDate = new Date(originalDate);
    
  
    startingDate.setDate(originalDate.getDate() - behindDays);
     const ist =  startingDate.toISOString();
    console.log("startingDate dateHandler1", startingDate, ist );
    setStartDate(ist)

  };
  

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function parseCustomDateString1(customDateString) {
    console.log(customDateString);
    const dayAbbreviation = customDateString?.slice(0, 3);
    const date = customDateString?.slice(3, customDateString?.length);
    console.log("date", date);
    const dateArray = date?.split(" ");
    const dayNumber = parseInt(dateArray[0]);
    const monthName = dateArray[1];
    const year = parseInt(dateArray[2]);

    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };
    console.log(monthName);
    const month = months[monthName];

    return { year, month, date: dayNumber };
  }

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    const hour = parseInt(time?.split(":")[0], 10);

    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  const localCon1 = (start, end, status) => {
    console.log(start, end);

    let t1 = "";
    let t2 = "";

    const st = checkAMorPM(start);
    if (st === "AM") {
      const hour = start?.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(start?.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${start?.split(":")[1]} PM`;
    }

    const en = checkAMorPM(end);
    if (en === "AM") {
      const hour = end?.split(":");
      t2 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(end?.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t2 = `${h}:${end?.split(":")[1]} PM`;
    }

    const formatTime = { formatTime: `${t1}-${t2}`, status: status };
    console.log("formatTime", formatTime);
    return formatTime;
  };

  const slotsCollector = (d, v, selectedSlot, date) => {
    console.log("on slot select : ", {d, v, selectedSlot, date})
    // console.log("---",selectedSlot + date)
    // if (v?.status === "selected") {
    //   return;
    // } else

    if (v?.status === "available") {
      handleModifySelectionArray(selectedSlot + date);

      let t = convertTime(selectedSlot, date);

      const slot = {
        start: t?.start_time,
        end: t?.end_time,
      };
      console.log("🚀 ~ slotsCollector ~ slot:", slot);
      const existingIndex = selectedSlots.findIndex(
        (s) => s.start === slot.start && s.end === slot.end
      );

      if (existingIndex !== -1) {
        // If the slot exists, remove it
        const updatedSelectedSlots = selectedSlots.filter(
          (_, index) => index !== existingIndex
        );
        setSelectedSlots(updatedSelectedSlots);
      } else {
        // If the slot doesn't exist, push it
        setSelectedSlots((prevSelectedSlots) => [...prevSelectedSlots, slot]);
      }
    }
  };

  function handleModifySelectionArray(string) {
    setSelectedSlotsForShowSelections((prevSelectedSlots) => {
      const index = prevSelectedSlots.indexOf(string);
      if (index !== -1) {
        // If the string exists, remove it
        return prevSelectedSlots.filter((_, i) => i !== index);
      } else {
        // If the string doesn't exist, push it
        return [...prevSelectedSlots, string];
      }
    });
  }
  function convertTime(slots, dateString) {
    // Split the input string into components
    const [start, end] = slots?.split("-");
    const start_time = convertTimeStringToDate(start, dateString);
    const end_time = convertTimeStringToDate(end, dateString);

    console.log(start_time, end_time);

    return {
      start_time: start_time.toISOString(),
      end_time: end_time.toISOString(),
    };
  }

  function convertTimeStringToDate(timeString, datestring) {
    // Split the time string into hours, minutes, and AM/PM parts
    const date1 = parseCustomDateString(datestring);
    const [time, period] = timeString?.split(" ");
    const [hours, minutes] = time?.split(":");

    // Convert hours to 24-hour format if needed
    const hours24 =
      period === "AM"
        ? hours === "12"
          ? "00"
          : hours
        : hours === "12"
        ? "12"
        : String(parseInt(hours, 10) + 12);

    // Create a new Date object using the current date and the extracted time components
    const currentDate = new Date(date1);
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours24,
      minutes
    );

    return newDate;
  }

  function parseCustomDateString(customDateString) {
    const dayAbbreviation = customDateString?.slice(0, 3);
    const date = customDateString?.slice(3, customDateString.length);
    console.log("date", date);
    const dateArray = date?.split(" ");
    const dayNumber = parseInt(dateArray[0]);
    const monthName = dateArray[1];
    const year = parseInt(dateArray[2]);
    // const dayNumber = parseInt(customDateString.slice(3, 5), 10);
    // const monthName = customDateString.slice(6, 9);
    // const year = parseInt(customDateString.slice(10), 10);

    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const month = months[monthName];

    return new Date(year, month, dayNumber);
  }

  async function approveOrRejectAppointmentFunc(status) {
    let obj = {
      appointment_id: appointmentDetail?._id,
      status: status,
    };
    try {
      const resp = await getDashboard_approveOrRejectAppontment(obj);
       return resp
    } catch (err) {
      throw new Error(err)
    }
  }

  const ResheduleAppointmentFun = async()=> {
   
    let body = {
      appointment_id : appointmentDetail?._id,
     
      suggested_slots:  selectedSlots
    }

    if(selectedDentist){
      body.dentist_id = selectedDentist
    }

   try {
    const resp  = await reseduleAppointment(body)
   } catch (error) {
    console.log(error)
   }
  }

  const handleRescheduleConfirm = async()=>{
    if(!selectedSlots.length > 0){
       toast.error("Please select the slots")
       return;
    }
    if(selectedSlots.length < 2){
       toast.error("Please select atleast 2 slots")
       return;
    }

   if(selectedSlots.length > 0){
      // await approveOrRejectAppointmentFunc("rejected")
      await ResheduleAppointmentFun()
      handleClose()
      toast.success("Suggested slots Sent successfully")
      handleConfirmInParent()
   }else{
    await approveOrRejectAppointmentFunc("rejected")
    handleClose()
    toast.success("Request Canceled")
    handleConfirmInParent()
   }
  }

  useEffect(()=>{
    if(selectedDentist){
      getDentistProfile(selectedDentist)
    }

  },[selectedDentist])


  const getDentistProfile = async(dentistId)=>{
    try {
       const resp = await makeHttpRequest("get" ,`users/getDentist/${dentistId}`)
       setDentistProfile(resp?.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Re-Schedule Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="appointment re-schedule">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="booking-doc-info">
                      <div className="row">
                      
                      
                         <div className="col-md-6">
                         {
                         selectedDentist ?
                        ( <>
                         <a class="booking-doc-img" href="#">
                            <img
                              width={50}
                              height={50}
                              src={dentistProfile?.profile_image ? 
                                        profile_image_base_url +
                                        dentistProfile?.profile_image
                                       : avatar}
                              // src="./Assets/hero-image-1.png"
                              alt="User"
                            />
                          </a>
                          <h6>
                            <a href="#">{dentistProfile?.full_name}</a>
                          </h6>
                         </>)
                         :
                         ( <>
                         <a class="booking-doc-img" href="#">
                            <img
                              width={50}
                              height={50}
                              src={clinicDetails?.profile_image ? 
                                        profile_image_base_url +
                                        clinicDetails?.profile_image
                                       : avatar}
                              // src="./Assets/hero-image-1.png"
                              alt="User"
                            />
                          </a>
                          <h6>
                            {/* <a href="#">The patient made this appointment without a dentist's preference</a> */}
                          </h6>
                         </>)
                         
                         }
                         
                        </div>
                      
                       
                        <div className="col-md-6">
                          <h6>Select Dentist</h6>
                          <select
                            class="form-select provider_select"
                            aria-label="Default select example"
                            onChange={(e) => setSelectedDentist(e.target.value)}
                            value={selectedDentist}
                          >
                            {providerList.map((val) => (
                              <option value={val?._id} label={val?.full_name} />
                            ))}
                            <option value="" label="Any" />
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-4 col-md-6">
                    <p class="text-muted">Appointment Date</p>
                    <h4 class="mb-1">{appointmentDate?.date}</h4>
                    <p class="text-muted">{appointmentDate?.day}</p>
                  </div>
                  <div class="col-12 col-sm-8 col-md-6 text-sm-end">
                    <p class="text-muted text-right">Current date</p>
                    <div class="datepicker-icon">
                      <input
                        type="date"
                        onChange={dateHandler1}
                        min={getCurrentDate()}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="card booking-schedule schedule-widget">
                  <div class="schedule-header">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="day-slot">
                          <ul>
                            <li className="left-arrow">
                              <Link to="" onClick={() => incrementWeek("back")}>
                                <i className="fa fa-chevron-left"></i>
                              </Link>
                            </li>
                            <li>
                              <p className="appt_day">Mon</p>
                              <span>{formik?.values?.Monday?.slice(3)}</span>
                            </li>
                            <li>
                              <p className="appt_day">Tue</p>
                              <span>{formik?.values?.Tuesday?.slice(3)}</span>
                            </li>
                            <li>
                              <p className="appt_day">Wed</p>
                              <span>{formik.values.Wednesday?.slice(3)}</span>
                              {/* <span className="slot-date">13 Nov <small className="slot-year">2023</small></span> */}
                            </li>
                            <li>
                              <p className="appt_day">Thu</p>
                              <span>{formik.values.Thursday?.slice(3)}</span>
                              {/* <span className="slot-date">14 Nov <small className="slot-year">2023</small></span> */}
                            </li>
                            <li>
                              <p className="appt_day">Fri</p>
                              <span>{formik.values.Friday?.slice(3)}</span>
                              {/* <span className="slot-date">15 Nov <small className="slot-year">2023</small></span> */}
                            </li>
                            <li>
                              <p className="appt_day">Sat</p>
                              <span>{formik.values.Saturday?.slice(3)}</span>
                              {/* <span className="slot-date">16 Nov <small className="slot-year">2023</small></span> */}
                            </li>
                            <li>
                              <p className="appt_day">Sun</p>
                              <span>{formik.values.Sunday?.slice(3)}</span>
                              {/* <span className="slot-date">17 Nov <small className="slot-year">2023</small></span> */}
                            </li>
                            <li className="right-arrow">
                              <Link onClick={() => incrementWeek("next")}>
                                <i className="fa fa-chevron-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="schedule-cont">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="time-slot">
                          <ul className="clearfix">
                            <li>
                              {formik2?.values?.Monday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Monday;

                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    } 
                                   `}
                                    onClick={() =>
                                      slotsCollector(
                                        "Monday",
                                        val,
                                        val?.formatTime,
                                        formik?.values?.Monday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* {console.log(val?.status)} */}
                                  </Link>
                                );
                              })}
                            </li>
                            <li>
                              {formik2?.values?.Tuesday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Tuesday; // Corrected the day value from 'Monday' to 'Tuesday'
                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    } 
                                   `}
                                    onClick={() =>
                                      slotsCollector(
                                        "Tuesday",
                                        val,
                                        val?.formatTime,
                                        formik.values.Tuesday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* <span>AM</span> */}
                                  </Link>
                                );
                              })}
                            </li>

                            <li>
                              {formik2?.values?.Wednesday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Wednesday;
                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      slotsCollector(
                                        "Tuesday",
                                        val,
                                        val?.formatTime,
                                        formik.values.Wednesday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* <span>AM</span> */}
                                  </Link>
                                );
                              })}
                            </li>

                            <li>
                              {formik2?.values?.Thursday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Thursday;
                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      slotsCollector(
                                        "Tuesday",
                                        val,
                                        val?.formatTime,
                                        formik.values.Thursday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* <span>AM</span> */}
                                  </Link>
                                );
                              })}
                            </li>

                            <li>
                              {formik2?.values?.Friday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Friday;
                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      slotsCollector(
                                        "Tuesday",
                                        val,
                                        val?.formatTime,
                                        formik.values.Friday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* <span>AM</span> */}
                                  </Link>
                                );
                              })}
                            </li>

                            <li>
                              {formik2?.values?.Saturday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Saturday;
                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      slotsCollector(
                                        "Tuesday",
                                        val,
                                        val?.formatTime,
                                        formik.values.Saturday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* <span>AM</span> */}
                                  </Link>
                                );
                              })}
                            </li>

                            <li>
                              {formik2?.values?.Sunday?.map((val) => {
                                const string =
                                  val?.formatTime + formik?.values?.Sunday;
                                return (
                                  <Link
                                    key={val?.formatTime} // Ensure each item has a unique key
                                    className={`timing ${
                                      selectedSlotsForShowSelections.includes(
                                        string
                                      )
                                        ? "selected"
                                        : val?.status === "available"
                                        ? "available"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      slotsCollector(
                                        "Tuesday",
                                        val,
                                        val?.formatTime,
                                        formik.values.Sunday
                                      )
                                    }
                                    disabled={val?.status === "selected"}
                                  >
                                    <span>{val?.formatTime}</span>
                                    {/* <span>AM</span> */}
                                  </Link>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div class="submit-section proceed-btn text-end">
            
                  <a class="theme_btn submit-btn"
                  onClick={handleRescheduleConfirm}
                  >Confirm</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-white rounded-4" onClick={handleClose}>
          Close
        </button>
        {/* Add more action buttons here if needed */}
      </Modal.Footer>
    </Modal>
  );
};
