import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import companyLogoicon from "../../Assets/Logos/company-logo2.png";
import companyLogo from "../../Assets/Logos/logo1.png";
import "./HomeNav.scss";
// import { Form, Button } from "react-bootstrap";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { toast } from "react-toastify";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Tooltip } from "antd";
import { getNearestClinic } from "../../utils/httpServices/httpService";
import ContactForm from "../ContactForm/ContactForm";

export default function HomeNav() {
  const navigate = useNavigate();
  const libraries = ["places"];
  const searchBoxRefStreet = useRef(null);
  const [validLocation, setValidLocation] = useState(false);
  const [clinicsLIst, setClinicsLIst] = useState([]);
  // const [openMenu, setOpenMenu] = useState(true)

  function displayNavbar() {
    const navbar = document.getElementById("HomeNavbar");
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
      navbar.classList.add("hide");
    } else {
      navbar.classList.remove("hide");
      navbar.classList.add("show");
    }
  }

  const [details, setDetails] = useState({
    lat: 0,
    lng: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  //   libraries,
  // });

  const [countryName, setCountryName] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const [account_type, setAccountType] = useState(null);
  const token = localStorage.getItem("token");
  const user_data = localStorage.getItem("user_details");

  const [showContactUs, setShowContactUs] = useState(false)

  const handleShowContact = () => {
    setShowContactUs(true)
  }

  const handleCloseContactUs = () => {
    setShowContactUs(false)

  }

  useEffect(() => {
    if (user_data !== null) {
      console.log(user_data);
      const parsedData = JSON.parse(user_data);
      setAccountType(parsedData.user_type);
    }
  }, []);
  const handlePopupOpen = () => {
    setShowPopup(true);
  };
  console.log(account_type, "account_type");
  useEffect(() => {
    let list = localStorage.getItem("clinic-lists");
    setClinicsLIst(JSON.parse(list));
    // Add a scroll event listener

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let navdefault = document.getElementById("navbardefault");
    let navabrOnscroll = document.getElementById("navabrOnscroll");
    let animNone = document.getElementById("animateSearch");
    let animActive = document.getElementById("navbarSupportedContent2");

    // Check the scroll position and update the 'scrolled' state
    console.log("🚀 ~ handleScroll ~ window:", window.scrollY)
    if (window.scrollY > 100) {
      // setScrolled(true);
      navdefault.style.display = "none";
      navabrOnscroll.style.display = "block";
      animNone.style.display = "none";
      animActive.classList.add("animateSearchNew");
    } else {
      // setScrolled(false);
      navdefault.style.display = "block";
      navabrOnscroll.style.display = "none";
      animNone.style.display = "block";
      animActive.classList.remove("animateSearchNew");
    }
  };

  // const onMapLoadStreet = (map) => {

  //     const searchBox = new window.google.maps.places.SearchBox(
  //         searchBoxRefStreet.current
  //     );
  //     searchBox.addListener("places_changed", () => {
  //         const places = searchBox.getPlaces();
  //         if (places.length === 0) {
  //             return;
  //         }
  //         const loc = places[0].formatted_address;
  //         // setstreet_address(loc);
  //         console.log(places)

  //         setDetails({
  //                    lat: places[0].geometry.location.lat(),
  //                    lng: places[0].geometry.location.lng(),
  //               });
  //     });
  // };

  // const locationHandler = ()=>{
  //     localStorage.setItem('lat', details.lat);
  //     localStorage.setItem('lng', details.lng);
  // }
  const onMapLoadStreet = (map) => {
    const searchBox = new window.google.maps.places.SearchBox(
      searchBoxRefStreet.current
    );

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        // No valid places found
        console.log("Invalid address entered");
        return;
      }

      const loc = places[0].formatted_address;
      console.log(places);

      localStorage.setItem("address", JSON.stringify(loc));

      // Extract country name from address components
      const countryComponent = places[0].address_components.find((component) =>
        component.types.includes("country")
      );
      const countryName1 = countryComponent ? countryComponent.long_name : "";

      console.log("Country Name:", countryName1);
      setCountryName(countryName1);
      // Assuming that having at least one place means a valid address
      // You may add more validation based on your requirements
      if (isValidPlace(places[0])) {
        setDetails({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng(),
        });
        setValidLocation(true);
      } else {
        console.log("Invalid address entered");
        toast.error("Please enter valid address.");
        setValidLocation(false);
      }
    });
  };

  // const sessionToken = useMemo(
  //   () => new window.google.maps.places.AutocompleteSessionToken(),
  //   [window.google.maps.places.AutocompleteSessionToken],
  // );
  // console.log("🚀 ~ HomeNav ~ sessionToken:", sessionToken)

  const isValidPlace = (place) => {
    // Example validation: Check if the place has a valid formatted address

    return (
      place && place.formatted_address && place.formatted_address.trim() !== ""
    );
  };

  const locationHandler = async () => {
    // return false;
    console.log(searchBoxRefStreet.current.value, "search box ref street");

    // Assuming you have a location name in searchBoxRefStreet.current.value
    if (!validLocation) {
      const locationName = searchBoxRefStreet.current.value;

      try {
        // Fetch latitude and longitude from Google Maps Geocoding API
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            locationName
          )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE}`
        );

        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng, formatted_address } =
            data.results[0].geometry.location;
          const country = data.results[0].address_components.find((component) =>
            component.types.includes("country")
          ).long_name;

          console.log("Latitude:", lat);
          console.log("Longitude:", lng);
          console.log("Country:", country);
          console.log("Formatted Address:", formatted_address);

          // Store data in localStorage or wherever you need
          localStorage.setItem("lat", lat);
          localStorage.setItem("lng", lng);
          localStorage.setItem("country", country);
          localStorage.setItem(
            "address",
            JSON.stringify(data?.results[0].formatted_address)
          );
          if (clinicsLIst) {
            checkClinicsExist(lat, lng, "/recentSearch")
            // navigate("/recentSearch");
          } else {
            checkClinicsExist(lat, lng, "/questionnair?questionNum=0")

          }
        } else {
          toast.error("Please use precise location");
        }
      } catch (error) {
        toast.error("Please add a valid address");
      }
    } else {
      localStorage.setItem("lat", details?.lat);
      localStorage.setItem("lng", details?.lng);
      localStorage.setItem("country", countryName);
      if (clinicsLIst) {

        checkClinicsExist(details?.lat, details?.lng, "/recentSearch")
      } else {
        checkClinicsExist(details?.lat, details?.lng, "/questionnair?questionNum=0")
      }
    }
  };
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude + " " + longitude, "User's address:");
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE}`
            );
            const data = await response.json();
            console.log(data, "User's address:");
            searchBoxRefStreet.current.value =
              data?.results[0]?.formatted_address;
            console.log(searchBoxRefStreet, "searchBoxRefStreet");
          } catch (error) {
            console.error("Error fetching address:", error.message);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const checkClinicsExist = async (lat, long, route) => {
    const body = {
      coordinates: [long, lat],
    }
    try {
      const resp = await getNearestClinic(body)
      if (resp?.data?.length > 0) {

        navigate(route);
      } else {
        handleShowContact()
      }

    } catch (error) {
      // return true;
      console.log(error)
    }
  }

  const handleContactUsSubmited = () => {
    if (searchBoxRefStreet.current) {
      searchBoxRefStreet.current.value = '';
    }
  }

  const handleKeyPress = (event) => {

    if (event.key === 'Enter') {
      locationHandler()
    }
  };



  const [isFixedTop, setIsFixedTop] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  let lastScrollTop = 0;

  useEffect(() => {
      const handleResize = () => {
          if (window.innerWidth > 992) {
              setIsFixedTop(false);
          } else {
              setIsFixedTop(true);
          }
      };

      const handleScrolls = () => {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          if (scrollTop > lastScrollTop && scrollTop > 400) {
              setIsVisible(true);
          } else {
              setIsVisible(false);
          }
          lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      };

      handleResize(); // Check initially

      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScrolls);
      return () => {
          window.removeEventListener('resize', handleResize);
          window.removeEventListener('scroll', handleScrolls);
      };
  }, []);

  return (
    <>
      <nav
        id="navbardefault"
        
        className={`navbar patient-navbar navbar-expand-lg shadow bg-black fixed-top ${scrolled ? "navbar-scrolled" : ""
          }`}
      >
        <div className="container">
          <Link className="navbar-brand text-uppercase flex-grow-1" to="/">
            <img
              src={companyLogo}
              alt="Logo"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            // type="button"
            // data-bs-toggle="collapse"
            // data-bs-target="#HomeNavbar"
            // aria-controls="HomeNavbar"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
            onClick={displayNavbar}
          >
            <i class="fa-solid fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="HomeNavbar">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link text-white" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white"
                  to={`${process.env.REACT_APP_DENTIST_BLOG_LINK}`}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/support">
                  Support
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/find-dentist">
                  For Dentists
                </Link>
              </li>
            </ul>

            {token === null ? (
              <form className="d-flex" role="search">
                <Link
                  to="/login"
                  class="theme_btn text-decoration-none"
                // type="button"
                >
                  Log in
                </Link>
              </form>
            ) : token !== null && account_type === "user" ? (
              <Link
                to="/user-profile/tab1"
                class="theme_btn text-decoration-none"
              // type="button"
              >
                Go to User Profile
              </Link>
            ) : (
              <Link
                to="/dashboard"
                class="theme_btn text-decoration-none"
              // type="button"
              >
                Go to Dashboard
              </Link>
            )}
          </div>
        </div>
      </nav>

      <nav
        id="navabrOnscroll"
        style={{display:'none'}}
        className={`navbar patient-navbar navbar-expand-lg shadow bg-black fixed-top ${scrolled ? "navbar-scrolled" : ""
          }`}
      >
        <div className="container">
          <Link className="navbar-brand flex-grow-1" to="/">
            <img
              src={companyLogo}
              alt="Logo"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent2"
            aria-controls="navbarSupportedContent2"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa-solid fa-bars"></i>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end "
            id="navbarSupportedContent2"
          >
            <div className="hero__search-box" id="navSearchAnimate">
              <Tooltip title="Detect a location">
                <span
                  className="fa-solid fas fa-location form-control-feedback"
                  onClick={handleCurrentLocation}
                  style={{ cursor: "pointer" }}
                ></span>
              </Tooltip>
              <input
                className="form-control"
                placeholder="Your Address"
                name="school_address"
                type="textarea"
                onFocus={handlePopupOpen}
                onChange={(e)=>{searchBoxRefStreet.current.value = e.target.value}}
                onClick={handlePopupOpen}
                onKeyPress={handleKeyPress}
                ref={searchBoxRefStreet}
              />

              {showPopup && (
                <div className="map-popup">
                  <GoogleMap onLoad={onMapLoadStreet}></GoogleMap>
                </div>
              )}
              <Link
                // to={
                //   validLocation && !clinicsLIst
                //     ? "/questionnair?questionNum=0"
                //     : validLocation && clinicsLIst
                //     ? "/recentSearch"
                //     : undefined
                // }
                className="theme_btn "
                // type="button"
                onClick={locationHandler}
              >
                {" "}
                Find A Dentist{" "}
              </Link>
            </div>
          </div>
        </div>
        <ContactForm
          show={showContactUs}
          handleClose={handleCloseContactUs}
          handleSubmit={handleContactUsSubmited}
        />
      </nav>

      <nav id="onScrollCustmNav" className={`cusTomNavbar ${isFixedTop ? 'fixed-top' : ''} ${isVisible ? 'visible' : 'hidden'} `} >
        <div className="container-fluid p-0">
          <div className="headerWrapper">
            <Link className="logotext d-flex align-items-center" to="/">
              <img
                src={companyLogoicon}
                alt="Logo"
                className="d-inline-block align-text-top"
              />
              <span> Find your ideal dentist.</span>
            </Link>
            <div className="hero__search-box" id="navSearchAnimate">
              <Tooltip title="Detect a location">
                <span
                  className="fa-solid fas fa-location form-control-feedback"
                  onClick={handleCurrentLocation}
                  style={{ cursor: "pointer" }}
                ></span>
              </Tooltip>
              <input
                className="form-control"
                placeholder="Your Address"
                name="school_address"
                type="textarea"
                onFocus={handlePopupOpen}
                onClick={handlePopupOpen}
                onKeyPress={handleKeyPress}
                ref={searchBoxRefStreet}
              />

              {showPopup && (
                <div className="map-popup">
                  <GoogleMap onLoad={onMapLoadStreet}></GoogleMap>
                </div>
              )}
              <Link className="theme_btn"
                // type="button"
                onClick={locationHandler}
              >
                {" "}
                Find A Dentist{" "}
              </Link>
            </div>

          </div>

        </div>
      </nav>

    </>
  );
}
