import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import doctor01 from "../../Assets/doctor.png";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";

import {
  getDentistProfileByIdService,
  imageUpload,
  successNotification,
  errorNotification,
  editDentistProfile,
} from "../../utils/httpServices/httpService";

export default function EditProfile({
  open,
  handleClose,
  editProfile_id,
  branch_id,
  getProviderListById,
}) {
  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  console.log("edit profile id", editProfile_id);

  async function getDentistProfileById(id) {
    console.log("inside function id", id);
    try {
      const resp = await getDentistProfileByIdService(id);
      console.log("dentist profile detail by id", resp);
      if (resp?.code === 200) {
        let date_data = [];
        if (
          resp?.data?.joining_date &&
          resp?.data?.joining_date !== "" &&
          resp?.data?.joining_date !== null
        )
          date_data = resp?.data?.joining_date.split("T");

        profile.setValues({
          first_name: resp?.data?.first_name,
          last_name: resp?.data?.last_name,
          email: resp?.data?.email,
          profile_image: resp?.data?.profile_image,
          experience: resp?.data?.experience,
          joining_date: date_data.length !== 0 ? date_data[0] : "",
          designation: resp?.data?.designation,
          address: resp?.data?.address,
        });
      }
    } catch (error) {
      console.log("upload profile image error", error);
    }
  }

  React.useEffect(() => {
    console.log("user effect trigger");
    if (editProfile_id !== null) getDentistProfileById(editProfile_id);
  }, [editProfile_id]);

  const profile = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      profile_image: "",
      experience: "",
      joining_date: "",
      designation: "",
      address: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      // email: Yup.string().email("Please enter a valid email address").required("Last Name is required"),
      profile_image: "",
      experience: Yup.string().required("Experience is required"),
      // joining_date: Yup.string().required("Joining date is required"),
      designation: Yup.string().required("Designation is required"),
      // address: Yup.string().required("Address is required"),
    }),
    onSubmit: (values) => {
      console.log("form values", values);
      editProviderProfile(values, editProfile_id);
    },
  });

  const providerProfilePicture = async (event) => {
    console.log("upload image data", event);
    const file = event.target.files[0];
    if (file) {
      if (
        file.type.split("/").includes("gif") ||
        file.type.split("/").includes("jpg") ||
        file.type.split("/").includes("jpeg") ||
        file.type.split("/").includes("png")
      ) {
        if (file.size <= 10000000) {
          const data = new FormData();
          data.append("path", "userProfile");
          data.append("media", file);
          try {
            const resp = await imageUpload(data);
            console.log("upload provider profile image response", resp);
            if (resp?.code === 200) {
              successNotification("Image uplaoded Successfully");
              profile.setFieldValue("profile_image", resp.path);
              // console.log("provider profile profile data", addProviderProfile)
            }
          } catch (error) {
            console.log("upload profile image error", error);
          }
        } else {
          errorNotification("Image size is greater than 2MB");
        }
      } else {
        errorNotification(
          "Please upload image in supported format (PNG,JPG,GIF)"
        );
      }
    }
  };

  async function editProviderProfile(values, id) {
    const { first_name, ...rest } = values;
    const corrected_first_name = first_name.startsWith("Dr.")
      ? first_name
      : `Dr. ${first_name}`;

    const corrected_provider_tab_1 = {
      ...rest,
      first_name: corrected_first_name,
    };

    const data = {
      ...corrected_provider_tab_1,
    };
    console.log("profile object", data);
    try {
      const resp = await editDentistProfile(data, id);
      console.log(" provider profile response", resp);
      if (resp?.code === 200) {
        successNotification("Profile Editted Successfully");
        getProviderListById(branch_id);
        handleClose();
      }
    } catch (err) {
      // alert(err);
    }
  }

  const handleSave = () => {
    // Add your save logic here
    // For now, let's just close the modal
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-container"
      >
        <Box className="modal-content">
          <span className="cross_mdl" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </span>
          <div className="Profile_cntainer">
            <div className="profile-header">
              <div className="profile-img">
                <img
                  src={
                    profile.values.profile_image !== ""
                      ? profile_image_base_url + profile.values.profile_image
                      : doctor01
                  }
                  alt="doctor"
                />
              </div>
              <label
                htmlFor="uploadImage"
                className="btn btn-sm btn-primary rounded-pill"
              >
                <i class="fa-solid fa-pencil"></i>
              </label>
              <input
                type="file"
                name=""
                id="uploadImage"
                onChange={providerProfilePicture}
              />
            </div>
            <div className="profile-body mt-4">
              <form onSubmit={profile.handleSubmit}>
                <div class="row">
                  <div class="col-md-6 col-6 mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      aria-label="First name"
                      id="first_name"
                      name="first_name"
                      onChange={profile.handleChange}
                      value={profile.values.first_name}
                      onBlur={profile.handleBlur}
                    />
                    {profile.touched.first_name && profile.errors.first_name ? (
                      <p className="validation_message">
                        {profile.errors.first_name}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="col-md-6 col-6 mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                      id="last_name"
                      name="last_name"
                      onChange={profile.handleChange}
                      value={profile.values.last_name}
                      onBlur={profile.handleBlur}
                    />

                    {profile.touched.last_name && profile.errors.last_name ? (
                      <p className="validation_message">
                        {profile.errors.last_name}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="col-md-6 col-sm-12 mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email "
                      aria-label="Email"
                      id="email"
                      name="email"
                      onChange={profile.handleChange}
                      value={profile.values.email}
                      onBlur={profile.handleBlur}
                    />
                    {profile.touched.email && profile.errors.email ? (
                      <p className="validation_message">
                        {profile.errors.email}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="col-md-6 col-sm-12 mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Designation"
                      aria-label="Designation"
                      id="designation"
                      name="designation"
                      onChange={profile.handleChange}
                      value={profile.values.designation}
                      onBlur={profile.handleBlur}
                    />
                    {profile.touched.designation &&
                    profile.errors.designation ? (
                      <p className="validation_message">
                        {profile.errors.designation}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="col-md-6 col-6 mb-2">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Experience in years"
                      aria-label="Experience"
                      id="experience"
                      name="experience"
                      onChange={profile.handleChange}
                      value={profile.values.experience}
                      onBlur={profile.handleBlur}
                    />
                    {profile.touched.experience && profile.errors.experience ? (
                      <p className="validation_message">
                        {profile.errors.experience}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="col-md-6 col-6 mb-2">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="select date"
                      aria-label="Designation"
                      id="joining_date"
                      name="joining_date"
                      onChange={profile.handleChange}
                      value={profile.values.joining_date}
                      onBlur={profile.handleBlur}
                    />
                    {profile.touched.joining_date &&
                    profile.errors.joining_date ? (
                      <p className="validation_message">
                        {profile.errors.joining_date}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div class="col-md-6 col-sm-12 mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Location"
                      aria-label="location"
                      id="address"
                      name="address"
                      onChange={profile.handleChange}
                      value={profile.values.address}
                      onBlur={profile.handleBlur}
                    />
                    {profile.touched.address && profile.errors.address ? (
                      <p className="validation_message">
                        {profile.errors.address}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div> */}
                </div>
                <div className="d-flex justify-content-end align-items-end">
                  <button className="theme_btn  rounded-2" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
