import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Tooltip } from "@mui/material";
import "./Appointments.scss";
// import { Link } from "react-router-dom";
import profile01 from "../../Assets/profile01.jpg";
import Header from "../../components/Header/Header";
import { DatePicker, Empty, Radio, Select } from "antd";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Footer from "../../components/Footer/Footer";
import { useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import Slider from "react-slick";
import Fade from "@mui/material/Fade";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "moment-timezone";

import avatar from "../../../src/Assets/avtar_img.jpeg";
import profilePlaceholder from "../../../src/Assets/noUserImage.png"
// --React-Calendar--

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
// ---srcoll----
import { Link, animateScroll as scroll } from "react-scroll";

import {
  addSearchingDetails,
  bookedSlot,
  bookedSlots,
  disabledSlot,
  disabledSlots,
  getAllClinicDentistSlots,
  getClicinRating,
  getClinicData,
  getClinicsData,
  getPatientExper,
  getPatientsExper,
  getProviderList,
  getProviderLists,
  getSlots,
  getSlott,
  loginService,
  makeHttpRequest,
  signupService,
  slotBooking,
  socialSignUpService,
} from "../../utils/httpServices/httpService";
import { useFormik } from "formik";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebaseConfig";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import * as Yup from "yup";
import { Data } from "@react-google-maps/api";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import ConfirmPopUp from "../../components/Appointments/index";
import Auth from "../../components/Appointments/Auth";
import AuthComponent from "../../components/Appointments/Auth";
import RateClinicModal from "../../components/Appointments/RateComponents";
import moment from "moment";
import CustomSelect from "../../components/Custom-select/CustomSelect";
import { getUserId, getisoDate } from "../../utils/common/common";
import { AdsClickOutlined, CalendarMonth } from "@mui/icons-material";
import BookConfirmed from "../../components/BookConfirmed/BookConfirmed";
import ProviderImages from "../../components/ProvideImages/ProviderImages";
import { decode } from "js-base64";
const { RangePicker } = DatePicker;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AnyReactComponent = () => (
  <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
    <LocationOnIcon style={{ color: "red" }} fontSize="large" />
  </div>
);

export default function Appointment() {
  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  const navigate = useNavigate();
  const [openRateModal, setOpenRateModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState();
  const [openConfirmDialag, setOpenConfirmDialog] = useState(false);
  const [day, setDay] = useState("");
  const [dateString, setDateString] = useState("");
  const { id, from } = useParams();
  const [data, setData] = useState({});
  const [ind, setInd] = useState(0);
  const [length, setLength] = useState(0);
  const [presentDate, setPresentDate] = useState("");
  const [open1, setOpen1] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [Day1, setDay1] = useState("");
  const [patients1, setPatients1] = useState([]);
  const [allBookedSlots, setAllBookedSlots] = useState([]);
  const [limit, setLimit] = useState(2);
  const [dentistSlots, setDentistSlots] = useState([]);
  const [count, setCount] = useState(0);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [dates, setDates] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [providerList, setProvderList] = useState([]);
  const [providerId, setProviderId] = useState("Any");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [signIn, setSignIn] = useState(false);
  const [image1, setImage1] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [allSlots, setAllSlots] = useState({});
  const [tokken, setTokken] = useState("");
  const [activeDiv, setActiveDiv] = useState("overview");
  // const [signInWithGoogle, setSignInWithGoogle] = useState(false)
  const [tickedSlot, setTickedSlot] = useState("");
  const [tickedDate, setTickedDate] = useState("");
  const [userData, setUserData] = useState({});
  console.log("🚀 ~ Appointment ~ userData:", userData)
  const [insurance, setInsurance] = useState("");
  const [clinicRating, setClinicRating] = useState([]);
  const [alreadyAccount, setAlreadyAccount] = useState(false);
  // const [socialData, setSocialData] = useState({})
  const [validUser, setValidUser] = useState(false);
  const [workingHours, setWorkingHours] = useState([]);
  const [date, setDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState({});
  const [slotsData, setSlotsData] = useState([]);
  const [dentist, setDentist] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [allDentistSlots, setAlldentistSlots] = useState([]);

  const [latestReviews, setLatestReviews] = useState([]);

  const [selectedSlotOriginal, setSelectedSlotOrignal] = useState(null);

  const map1 = new Map();

  const overviewRef = useRef();
  const contactRef = useRef();
  const reviewRef = useRef();
  const ourDentistsRef = useRef();

  let questionnairDetails = useMemo(() => {

    const questionnair = JSON.parse(localStorage.getItem('data'))
    let formatedQuestionnair = {}

    questionnair?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          formatedQuestionnair[key] = item[key].join(', ');
        } else {
          formatedQuestionnair[key] = item[key];
        }
      });
    });

    return formatedQuestionnair;


  }, []);


  //scrolling

  const providerDetails = useMemo(() => {
    return providerList?.find((obj) => obj?._id === dentist);
  }, [dentist, providerList]);

  const scrollToReference = (targetRef) => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
      // window.scrollBy(0, 100);
    }
  };

  map1.set("Monday", "Closed");
  map1.set("Tuesday", "Closed");
  map1.set("Wednesday", "Closed");
  map1.set("Thursday", "Closed");
  map1.set("Friday", "Closed");
  map1.set("Saturday", "Closed");
  map1.set("Sunday", "Closed");
  const token = tokken;
  let steps = [];
  token === "" ? (steps = ["Information", "Confirm"]) : (steps = ["Confirm"]);

  // const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const defaultProps = {
    center: { lat: lat, lng: lng },
    zoom: 11,
  };
  const locationCoordinates = {
    lat: lat,
    lng: lng,
  };

  const get_clinicRatings = async () => {
    try {
      const response = await getClicinRating();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGoogleSignIn = async (response) => {
    // const provider = new GoogleAuthProvider();
    try {
      //   const result = await signInWithPopup(firebaseAuth, provider);

      //   const body = {
      //     email: result?._tokenResponse?.email,
      //     first_name: result?._tokenResponse?.firstName,
      //     last_name: result?._tokenResponse?.lastName,
      //     full_name: result?._tokenResponse?.displayName,
      //     social_type: "google",
      //     social_id: result?.user?.uid,
      //   };
      //   const resp = await socialSignUpService(body);
      //   console.log(resp);
      //   setTokken(resp?.data?.token);
      //   localStorage.setItem("token", resp?.data?.token);
      //   setUserData(resp?.data?.user);
      //   let v = resp?.data?.user;
      //   localStorage.setItem("user_details", JSON.stringify(v));
      const result = jwtDecode(response?.credential);
      // console.log(a);
      const body = {
        email: result?.email,
        first_name: result?.given_name,
        last_name: result?.family_name,
        full_name: result?.name,
        social_type: "google",
        social_id: result?.sub,
      };

      const resp = await socialSignUpService(body);
      console.log("resp--------------------------");
      localStorage.setItem("token", resp?.data?.token);
      let v = resp?.data?.user;
      localStorage.setItem("user_details", JSON.stringify(v));

      setUserData(resp?.data?.user);
      setValidUser(true);
      setActiveStep(1);
      // setSignInWithGoogle(true)

      //   setUser({ displayName, email, idToken, photoUrl });
      console.log("signIn", result);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      Sunday: "",
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: "",
      Saturday: "",
    },
  });

  const formik1 = useFormik({
    initialValues: {
      Sunday: 7,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    },
  });

  const formik2 = useFormik({
    //For collecting the slots from api and display on UI
    initialValues: {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    },
  });

  // const formik3 = useFormik({ // To collect the slots onClick
  //     initialValues: {
  //         Sunday: [],
  //         Monday: [],
  //         Tuesday: [],
  //         Wednesday: [],
  //         Thursday: [],
  //         Friday: [],
  //         Saturday: []
  //     }
  // })

  const validationSchema1 = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formik4 = useFormik({
    // for sign
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema1,
  });

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Full Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // password: Yup.string()
    //   .min(8, "Password must be at least 8 characters")
    //   .required("Password is required"),
  });

  function convertTimetoNewFormatttedTime(slots) {
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

    const convertToLocalTime = (isoDateString) => {
      return new Date(isoDateString);
      // return new Date(date.getTime() - timeZoneOffset);
    };

    const convertToAMPM = (isoDateString) => {
      const date = new Date(isoDateString);
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
    };

    const formatTimeRange = (start, end) => {
      return convertToAMPM(start) + " - " + convertToAMPM(end);
    };

    const convertedSlots = slots.map((slot) => ({
      start_time: convertToLocalTime(slot.start),
      end_time: convertToLocalTime(slot.end),
      value: formatTimeRange(
        convertToLocalTime(slot.start),
        convertToLocalTime(slot.end)
      ),
      originalSlot: slot,
      isSlotBooked: slot.isSlotBooked,
    }));

    // if (!dentist) {
    // setSlotsData(convertedSlots);
    handleAddSlots(convertedSlots)
    // }
    // setAlldentistSlots(convertedSlots);
  }

  // push only future slots and one hour next from current time
  const handleAddSlots = (convertedSlots) => {

    const currentTime = new Date();
    currentTime.setHours(currentTime.getHours() + 1);

    const formattedSlots = []

    convertedSlots.forEach(slot => {
      if (slot.start_time > currentTime) {
        formattedSlots.push(slot);
      }
    })

    setSlotsData(formattedSlots);

  }



  const formik5 = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      // password: "",
      dob: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleNext,
  });

  const stepContent = [
    token === ""
      ? {
        label: "Select campaign settings",
        content: (
          <div className="first-step">
            <Typography variant="body1" gutterBottom>
              Please fill out the following information:
            </Typography>
            {signIn ? (
              <>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...formik4.getFieldProps("email")}
                />
                {formik4.errors.email && formik4.touched.email ? (
                  <p className="form-error">{formik4.errors.email}</p>
                ) : null}
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...formik4.getFieldProps("password")}
                />
                {formik4.errors.password && formik4.touched.password ? (
                  <p className="form-error">{formik4.errors.password}</p>
                ) : null}
              </>
            ) : (
              <>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...formik5.getFieldProps("first_name")}
                />
                {formik5.errors.first_name && formik5.touched.first_name ? (
                  <p className="form-error">{formik5.errors.first_name}</p>
                ) : null}
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...formik5.getFieldProps("last_name")}
                />
                {formik5.errors.last_name && formik5.touched.last_name ? (
                  <p className="form-error">{formik5.errors.last_name}</p>
                ) : null}
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...formik5.getFieldProps("email")}
                />
                {formik5.errors.email && formik5.touched.email ? (
                  <p className="form-error">{formik5.errors.email}</p>
                ) : null}
                {/* <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...formik5.getFieldProps("password")}
                  />
                  {formik5.errors.password && formik5.touched.password ? (
                    <p className="form-error">{formik5.errors.password}</p>
                  ) : null} */}
                <TextField
                  type="date"
                  label="Date of birth"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...formik5.getFieldProps("dob")}
                  // placeholder="select date"
                  //               inputProps={{
                  //   max: new Date().toISOString().split("T")[0]
                  // }}

                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                   max: new Date().toISOString().split("T")[0]
                   }}
                />
                {formik5.errors.dob && formik5.touched.dob ? (
                  <p className="form-error">{formik5.errors.dob}</p>
                ) : null}
              </>
            )}
            {/* <Typography className="text-center line mb-2">or</Typography> */}
            {/* <button className='btn-white rounded mb-4 w-100'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" role="img" className="icon ">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" fill="#4285F4"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" fill="#34A853"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z" fill="#FBBC05"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="#EA4335"></path>
                            </svg>
                            <span className='ms-2' onClick={handleGoogleSignIn}>{signIn ? 'Sign in with Google' : 'Sign up with Google'}</span>

                        </button> */}
            {/* <div className="socialLoginWrap">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    //   console.log(credentialResponse);
                    handleGoogleSignIn(credentialResponse);
                  }}
                />
              </div>
              <Typography className="text-center line mb-2">
                {signIn ? null : "Already have an account?"}{" "}
                <Link className="ms-2" to="" onClick={() => setSignIn(!signIn)}>
                  {signIn ? "Sign up" : "Log in"}
                </Link>
              </Typography> */}
          </div>
        ),
      }
      : null,
    {
      label: "Create an ad",
      content: (
        <div className="third-step mt-3">
          <div className="card shadow border-0 success-card mb-0">
            <img
              src={
                userData?.profile_image
                  ? `${process.env.REACT_APP_USER_PROFILE_IMAGE}${userData?.profile_image}`
                  : profilePlaceholder
              }
              className="avatar-sm"
              alt="userImage"
            />
            <div className="card-body">
              <div className="basic-dtl ">
                <Typography className="body2">Name: </Typography>
                <Typography className="body1">{userData?.full_name}</Typography>
              </div>
              <div className="basic-dtl">
                <Typography className="body2">Email: </Typography>
                <Typography className="body1">{userData?.email}</Typography>
              </div>
              {userData?.dob && (
                <div className="basic-dtl">
                  <Typography className="body2">DOB: </Typography>
                  <Typography className="body1">{new Date(userData?.dob).toLocaleDateString()}</Typography>
                </div>
              )}

              <div className="basic-dtl ">
                <Typography className="body2">Insurance Carrier: </Typography>
                <Typography className="body1">{insurance}</Typography>
              </div>

              <div className="basic-dtl ">
                <Typography className="body2">Appointment Date: </Typography>
                <Typography className="body1">{tickedDate}</Typography>
              </div>
              <div className="basic-dtl ">
                <Typography className="body2">Time: </Typography>
                <Typography className="body1">{tickedSlot}</Typography>
              </div>
              {providerDetails && (
                <div className="basic-dtl align-items-start justify-content-start flex-column">
                  <Typography className="body2">Dentist Name: </Typography>
                  <Typography className="body1">
                    {providerDetails?.full_name}
                  </Typography>
                </div>
              )}
              <div className="basic-dtl align-items-start justify-content-start flex-column">
                <Typography className="body2">Location: </Typography>
                <Typography className="body1">{data?.address}</Typography>
              </div>
              {/* <div className="success-cont">
                                <i className="fas fa-check" />
                                <h3>Appointment booked Successfully!</h3>
                                <p>
                                    Appointment booked with <strong>Dr. Shahram Sayedna</strong>
                                    <br /> on <strong>12 Nov 2023 5:00PM to 6:00PM</strong>
                                </p>
                            </div> */}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [test, setTest] = useState(map1);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // let googleMapsUrl = `https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&center=${lat},${lng}&zoom=15`;

  useEffect(() => {
    if (id) {
      getData();
      getLatestRating(id);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getPatients();
    }
  }, [limit]);

  useEffect(() => {
    const fetchData = async () => {
      await getData();
      getSlotsForAnyDentistSlot();
      // if (providerId !== "Any") {
      //   getSlot();
      // } else {
      //   // await getData();
      //   getSlotsForAnyDentistSlot();
      // }
    };

    fetchData();
  }, [providerId, startDate, endDate]);

  const refectInitalData = () => {
    if (id) {
      getData();
      getLatestRating(id);
    }
  }

  function getSlotsForAnyDentistSlot() {
    try {
      for (let index = 0; index < workingHours?.length; index++) {
        var start = new Date(workingHours?.[index]?.start_time);
        var end = new Date(workingHours?.[index]?.end_time);

        const day = workingHours?.[index]?.day;
        const dateRange = formik?.values;
        const date = dateRange?.[day];
        const dateObject = parseCustomDateString1(date);

        if (end.getDate() !== start?.getDate()) {
          // Use dateObject with next day for end
          end.setDate(dateObject?.date + 1);
          end.setMonth(dateObject?.month);
          end.setFullYear(dateObject?.year);
        } else {
          // Use same dateObject for end
          end.setDate(dateObject?.date);
          end.setMonth(dateObject?.month);
          end.setFullYear(dateObject?.year);
        }

        start.setDate(dateObject?.date); // Use optional chaining here
        start.setMonth(dateObject?.month); // Use optional chaining here
        start.setFullYear(dateObject?.year); // Use optional chaining here
        // Check if end date is in next day


        const slot = [];

        while (start.getTime() + 60 * 60 * 1000 <= end.getTime()) {
          slot.push({
            start: start,
            end: new Date(start?.getTime() + 60 * 60 * 1000),
            status: "available",
          });

          start = new Date(start?.getTime() + 60 * 60 * 1000);
        }

        const formattedSlots = slot?.map((val1) => {
          const start1 = new Date(val1?.start)
            ?.toLocaleString()
            ?.split(", ")[1];
          const end1 = new Date(val1?.end)?.toLocaleString()?.split(", ")[1];

          const status = "available";
          return localCon1(start1, end1, status);
        });

        const updatedValues = [...formattedSlots];
        formik2.setFieldValue(day, updatedValues);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    const hour = parseInt(time?.split(":")[0], 10);

    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  const localCon = (start, end, presenTime, presentDay, day) => {
    const start_time = new Date(start)?.toLocaleTimeString("en-US", {
      hour12: false,
    });
    if (presentDay === day) {
      const dateObj1 = new Date(`2000-01-01T${presenTime}`); // We just need compare the time
      const dateObj2 = new Date(`2000-01-01T${start_time}`); // We just need compare the time

      // Compare the Date objects
      if (dateObj1 > dateObj2) {
        setShowTime(false);
      } else if (dateObj1 < dateObj2) {
        setShowTime(true);
      } else {
        setShowTime(true);
      }
    }
    const end_time = new Date(end)?.toLocaleTimeString("en-US", {
      hour12: false,
    });

    let t1 = "";
    let t2 = "";

    const st = checkAMorPM(start_time);
    if (st === "AM") {
      const hour = start_time?.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(start_time?.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${start_time?.split(":")[1]} PM`;
    }

    const en = checkAMorPM(end_time);
    if (en === "AM") {
      const hour = end_time?.split(":");
      t2 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(end_time?.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t2 = `${h}:${end_time?.split(":")[1]} PM`;
    }

    const formatTime = `${t1}-${t2}`;
    // console.log(formatTime);
    return formatTime;
  };

  const localCon1 = (start, end, status) => {

    let t1 = "";
    let t2 = "";

    const st = checkAMorPM(start);
    if (st === "AM") {
      const hour = start?.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(start?.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${start?.split(":")[1]} PM`;
    }

    const en = checkAMorPM(end);
    if (en === "AM") {
      const hour = end?.split(":");
      t2 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(end?.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t2 = `${h}:${end?.split(":")[1]} PM`;
    }

    const formatTime = { formatTime: `${t1}-${t2}`, status: status };
    return formatTime;
  };

  const getData = async () => {
    const token1 = localStorage.getItem("token");
    const user = localStorage.getItem("user_details");
    let user1 = JSON.parse(user);

    if (token1 != null && user && user1?.user_type === "user") {
      setTokken(token1);
      setUserData(user1);
      setActiveStep(1);
    }

    const clinic_data = localStorage.getItem("seraching_details");
    let variable = JSON.parse(clinic_data);

    setInsurance(variable?.insurance);
    try {
      let resp = {};
      let resp1 = [];
      const token = localStorage.getItem("token");
      const user_data = localStorage.getItem("user_details");
      // let v= JSON.parse(JSON.stringify(user_data))
      // console.log(v)

      if (
        token != null &&
        user_data != null &&
        user_data?.user_type === "user"
      ) {
        resp = await getClinicsData(id);
        resp1 = await getProviderLists(id);
      } else {
        resp = await getClinicData(id);
        resp1 = await getProviderList(id);
      }

      setData(resp?.data[0]);

      const doc_length = resp?.data[0]?.clinic_documents;

      if (doc_length.length > 6) {
        setInd(5);
      }

      setLength(doc_length.length);

      const date = new Date();
      const dateString = date.toString();
      const time = dateString?.split(" ");

      setPresentDate(`${time[2]} ${time[1]} ${time[3]}`);
      const dayOfWeek = date.getDay(); // Returns a number (0-6) representing the day of the week (Sunday is 0, Monday is 1, and so on)

      // Convert dayOfWeek to a string representation
      const dayName = daysOfWeek[dayOfWeek];

      resp?.data[0]?.working_hours?.forEach((val) => {
        const v = val?.enabled
          ? localCon(val?.start_time, val?.end_time, time[4], dayName, val?.day)
          : "Closed";

        if (val?.day === dayName) {
          v === "Closed" ? setOpen1(false) : setOpen1(true);
        }

        setDay1(dayName);
        map1.set(val?.day, v);
      });

      setTest(map1);
      setLat(resp?.data[0].location?.coordinates[1]);
      setLng(resp?.data[0].location?.coordinates[0]);
      setWorkingHours(resp?.data[0]?.working_hours);

      setProvderList(resp1?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPatients = async () => {
    try {
      const params = {
        branch_id: id,
        limit: limit,
      };
      let resp = {};
      const token = localStorage.getItem("token");
      const user_data = localStorage.getItem("user_details");
      resp = await getPatientExper(params);
      // if (
      //   token != null &&
      //   user_data != null &&
      //   user_data?.user_type === "user"
      // ) {
      //   resp = await getPatientsExper(params);
      // } else {
      //   resp = await getPatientExper(params);
      // }

      setCount(resp?.count);
      setPatients1(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const timeCompare = (date1String, date2String) => {
    const date1 = new Date(date1String);
    const date2 = new Date(date2String);

    return date1.getTime() === date2.getTime();
  };

  function isSlotBooked(slot, day, bookedSlot) {
    var result = false;
    for (let index = 0; index < bookedSlot.length; index++) {
      const element = bookedSlot[index];

      const dateObject = new Date(element?.start);
      const dayOfWeek = dateObject.getDay();

      let corrres_day = daysOfWeek[dayOfWeek];
      // console.log("corrres_day", corrres_day)
      // console.log("corrres_day === day", corrres_day === day)
      // console.log(new Date(element?.start).getTime() === new Date(slot?.start).getTime(), new Date(element?.start), new Date(slot?.start));
      // console.log(new Date(element?.end).getTime() === new Date(slot?.end).getTime(), new Date(element?.end), new Date(slot?.end));
      if (
        corrres_day === day &&
        new Date(element?.start).getTime() ===
        new Date(slot?.start).getTime() &&
        new Date(element?.end).getTime() === new Date(slot?.end).getTime()
      ) {
        // console.log("truekjfksd")
        result = true;
        break;
      }
    }

    return result;
  }

  const getClinicDentistSlots = async (selected_date) => {
    try {
      const isTimeOffsetPositive = new Date().getTimezoneOffset() > 0;

      const day = selected_date.format("dddd");
      const dateString = selected_date.format("YYYY-MM-DD");
      let date = new Date(dateString.toString());

      // Increment the date by one day if the timezone offset is positive
      if (isTimeOffsetPositive) {
        date.setDate(date.getDate() + 1);
      }

      // Reset hours, minutes, seconds, and milliseconds to 0
      date.setHours(0, 0, 0, 0);

      const isoDateString = date.toISOString();

      const response = await getAllClinicDentistSlots(
        id,
        day,
        isoDateString,
        dateString
      );

      if (response.status === 200) {
        convertTimetoNewFormatttedTime(response.data.data);
      }
    } catch (err) {
      console.error("Error", err.message);
    }
  };
  useEffect(() => {
    if (dentist) {
      getDentistSlots(selectedDate)
    } else {
      getClinicDentistSlots(selectedDate);
    }

  }, [selectedDate]);


  const getSlot = async (providerId) => {
    try {
      if (providerId) {
        let resp = {};
        let resp1 = [];
        let resp2 = [];

        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem("user_details");
        let v = JSON.parse(JSON.stringify(user_data));

        if (
          token != null &&
          user_data != null &&
          user_data?.user_type === "user"
        ) {
          resp = await getSlots(providerId);
          // console.log(resp?.data);
          setImage1(resp?.data?.profile_image);
          setName(resp?.data?.full_name);
          setAddress(resp?.data?.address);
          const params = {
            dentist_id: providerId,
            // start_date: startDate,
            // end_date: endDate,
          };

          resp1 = await bookedSlot(params);
          // resp2 = await disabledSlot(id)
        } else {
          resp = await getSlott(providerId);
          // console.log(resp?.data);
          setImage1(resp?.data?.profile_image);
          setName(resp?.data?.full_name);
          setAddress(resp?.data?.address);
          const params = {
            dentist_id: providerId,
            start_date: startDate,
            end_date: endDate,
          };

          resp1 = await bookedSlots(params);
          // resp2 = await disabledSlots(id)
        }
        // console.log(resp1)
        // const[resp, resp1] = Promise.all([getSlots(providerId), bookedSlots(params)])

        const dateRange = formik?.values;

        const modifiedSlotWithDate = resp?.data?.slots?.map((ele) => {
          const day = ele?.day;
          const date = dateRange[day];

          const dateObject = parseCustomDateString1(date);

          const newSlot = ele?.slot?.map((elem) => {
            const start = new Date(elem?.start);
            start.setDate(dateObject.date);
            start.setMonth(dateObject.month);
            start.setFullYear(dateObject.year);

            const end = new Date(elem?.end);
            end.setDate(dateObject.date);
            end.setMonth(dateObject.month);
            end.setFullYear(dateObject.year);

            return { start: start?.toISOString(), end: end?.toISOString() };
          });

          return { day: day, slot: newSlot };
        });


        modifiedSlotWithDate.forEach((val) => {
          const formattedSlots = val?.slot?.map((val1) => {
            const start1 = new Date(val1?.start)
              ?.toLocaleString()
              ?.split(", ")[1];
            const end1 = new Date(val1?.end)?.toLocaleString()?.split(", ")[1];

            const checker = isSlotBooked(val1, val?.day, resp1?.data);
            if (checker) {
              const status = "selected";
              return localCon1(start1, end1, status);
            } else {
              const status = "available";
              return localCon1(start1, end1, status);
            }
          });


          const updatedValues = [...formattedSlots];

          // // Set the concatenated values for the current day
          formik2.setFieldValue(val?.day, updatedValues);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ---Handle-Image-Popup---

  const handleImagePopup = (value) => {
    let modal = document.querySelector(".modal");

    if (value == "close") {
      modal.style.display = "none";
    } else if (value == "open") {
      modal.style.display = "block";
    }
  };

  // --replace-header-with-appointment-page

  const [isVisible, setIsVisible] = useState(true);

  const [showScrollBook, setShowScrollBook] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check if the user has scrolled down a certain amount (e.g., 100 pixels)
      const threshold = 600;
      const currentScrollY = window.scrollY;
      if (currentScrollY > threshold) {
        setShowScrollBook(true);
      } else {
        setShowScrollBook(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleVisibility = () => {
    // let clinicHeader = document.getElementById('clinicHeader');
    // let bookingCard = document.getElementById('appointment-card');
    setIsVisible((val) => !val);
  };

  //---Image-viewer--end

  // --DatePicker--
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  // --Stepper--
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  function parseCustomDateString(customDateString) {
    const dayAbbreviation = customDateString?.slice(0, 3);
    const date = customDateString?.slice(3, customDateString.length);
    const dateArray = date?.split(" ");
    const dayNumber = parseInt(dateArray[0]);
    const monthName = dateArray[1];
    const year = parseInt(dateArray[2]);
    // const dayNumber = parseInt(customDateString.slice(3, 5), 10);
    // const monthName = customDateString.slice(6, 9);
    // const year = parseInt(customDateString.slice(10), 10);

    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const month = months[monthName];

    return new Date(year, month, dayNumber);
  }

  function parseCustomDateString1(customDateString) {
    const dayAbbreviation = customDateString?.slice(0, 3);
    const date = customDateString?.slice(3, customDateString?.length);
    const dateArray = date?.split(" ");
    const dayNumber = parseInt(dateArray[0]);
    const monthName = dateArray[1];
    const year = parseInt(dateArray[2]);

    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };
    const month = months[monthName];

    return { year, month, date: dayNumber };
  }

  function convertTimeStringToDate(timeString, datestring) {
    // Split the time string into hours, minutes, and AM/PM parts
    const date1 = parseCustomDateString(datestring);
    const [time, period] = timeString?.split(" ");
    const [hours, minutes] = time?.split(":");

    // Convert hours to 24-hour format if needed
    const hours24 =
      period === "AM"
        ? hours === "12"
          ? "00"
          : hours
        : hours === "12"
          ? "12"
          : String(parseInt(hours, 10) + 12);

    // Create a new Date object using the current date and the extracted time components
    const currentDate = new Date(date1);
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours24,
      minutes
    );

    return newDate;
  }

  function convertTime(slots, dateString) {
    // Split the input string into components
    const [start, end] = slots?.split("-");
    const start_time = convertTimeStringToDate(start, dateString);
    const end_time = convertTimeStringToDate(end, dateString);


    return {
      start_time: start_time.toISOString(),
      end_time: end_time.toISOString(),
    };
  }
  const isDateFuture = (selectedDate) => {
    const t = parseCustomDateString1(selectedDate);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const selectedDateTime = new Date(t?.year, t?.month, t?.date);
    selectedDateTime.setHours(0, 0, 0, 0);

    return selectedDateTime >= currentDate;
  };
  async function formatDateTime(dateString, timeString) {
    // Create separate date objects for start and end times
    const startDate = new Date(dateString);
    const endDate = new Date(dateString);

    // Extracting the end time
    const endTimeParts = timeString?.split(" - ")[1]?.split(" ")[0]?.split(":");
    const endTimeString = timeString?.split(" - ")[1];

    let endHours = parseInt(endTimeParts[0], 10);
    const endMinutes = parseInt(endTimeParts[1], 10);

    if (endHours === 12 && endTimeString.includes("AM")) {
      endHours = 12;
    }
    if (endHours !== 12 && endTimeString.includes("PM")) {
      endHours += 12;
    }

    endDate.setHours(endHours);
    endDate.setMinutes(endMinutes);

    // Adjusting the start time
    const startTimeParts = timeString
      ?.split(" - ")[0]
      ?.split(" ")[0]
      ?.split(":");
    const startTimeString = timeString?.split(" - ")[0];
    let startHours = parseInt(startTimeParts[0], 10);
    const startMinutes = parseInt(startTimeParts[1], 10);

    if (startHours === 12 && startTimeString.includes("AM")) {
      endHours = 12;
    }
    if (startHours !== 12 && startTimeString.includes("PM")) {
      startHours += 12;
    }

    startDate.setHours(startHours);
    startDate.setMinutes(startMinutes);

    // Convert date objects to local time zone formatted strings
    const formattedStartTime = `${startDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })}, ${startDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
    const formattedEndTime = `${endDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })}, ${endDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;

    return {
      start_time: formattedStartTime,
      end_time: formattedEndTime,
    };
  }
  const [bookingLoading, setBookingLoading] = useState(false)

  const submitHandler = async () => {
    setBookingLoading(true)

    const dayOfWeek = selectedDate.format("dddd");

    // const DateStringUtC = new Date(
    //   new Date(selectedDate).setHours(0, 0, 0, 0)
    // ).toISOString();

    const DateStringUtC = getisoDate(selectedDate)



    const startDate = new Date(selectedDate)
      .toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    let selectedDateAndTime = await formatDateTime(selectedDate, tickedSlot);



    const cd = localStorage.getItem("seraching_details");
    const clinicData = JSON.parse(cd);


    // if (!clinicData) {
    //   console.log("search details not found");

    //   return;
    // }

    const body = {
      branch_id: data?._id,
      dentist_id: dentist === "Any" ? "" : dentist,
      // start_time: moment
      //   .tz(
      //     selectedDateAndTime.start_time,
      //     "M/D/YYYY, h:mm:ss A",
      //     // "Asia/Kolkata"
      //   )
      //   .utc()
      //   .toISOString(),
      // end_time: moment
      //   .tz(selectedDateAndTime.end_time, "M/D/YYYY, h:mm:ss A", 
      //   // "Asia/Kolkata"
      // )
      //   .utc()
      //   .toISOString(),

      // start_time: new Date(selectedDateAndTime.start_time).toISOString(),
      // end_time: new Date(selectedDateAndTime.end_time).toISOString(),

      start_time: selectedSlotOriginal.start,
      end_time: selectedSlotOriginal.end,

      reason: clinicData?.main_purpose || "",
      services: clinicData?.services,
      day: dayOfWeek,
      date: startDate,
      start: DateStringUtC,
    };
    if (clinicData && from !== "profile") {



      let addQuestBody = clinicData;
      addQuestBody.clinic_id = id;

      if (!token && userData?.email) {
        addQuestBody.guest_data = userData;
        addQuestBody.user_type = "guest";
      } else if (!token && !userData?.email) {
        console.log("user details not found");
        return;
      }
      try {
        const resp = await addSearchingDetails(addQuestBody);

        if (resp?.user_id) {
          body.user_id = resp?.user_id;
          handleBookAppointment(body);
        }
      } catch (error) {
        console.log(error.message);

      }
      finally {
        setBookingLoading(false)
      }
    } else {
      body.user_id = getUserId();
      handleBookAppointment(body);
    }
  };

  const handleBookAppointment = async (body) => {
    try {
      if(sessionStorage.getItem("src")){
        body.landing_page_id = JSON.parse(decode(sessionStorage.getItem("src")))._id;
        body.offer_code = JSON.parse(decode(sessionStorage.getItem("src"))).offer_code;
      }
      sessionStorage.removeItem("src")
      const resp = await slotBooking(body);

      // toast.success("Your appointment has been booked.");
      handleClose()
      
      setTimeout(()=>{

        handleShowConfirmedBook()
      },300)
      // navigate("/");
    } catch (error) {
      console.log(error);
    } finally {
      setBookingLoading(false)
    }
  };

  // const handleNextOld = async () => {
  //   try {
  //     let resp = {};
  //     if (signIn) {
  //       const body = {
  //         email: formik4?.values?.email,
  //         password: formik4?.values?.password,
  //       };
  //       resp = await loginService(body);
  //     } else {
  //       const body = {
  //         first_name: formik5?.values?.first_name,
  //         last_name: formik5?.values?.last_name,
  //         email: formik5?.values?.email,
  //         password: formik5?.values?.password,
  //         user_type: "user",

  //       };
  //       resp = await signupService(body);
  //     }
  //     console.log(resp);
  //     setTokken(resp?.data?.token);
  //     localStorage.setItem("token", resp?.data?.token);
  //     setUserData(resp?.data?.user);
  //     let v = resp?.data?.user;
  //     localStorage.setItem("user_details", JSON.stringify(v));

  //     setValidUser(true);
  //     setActiveStep(1);
  //     console.log("resp-------------->", resp);
  //   } catch (e) {
  //     // toast.error(e);
  //     console.log(e);
  //   }
  //   // const newActiveStep = isLastStep() && !allStepsCompleted()
  //   //     ? // It's the last step, but not all steps have been completed,
  //   //     // find the first step that has been completed
  //   //     steps.findIndex((step, i) => !(i in completed))
  //   //     : activeStep + 1;
  //   // // if()
  //   // setActiveStep(newActiveStep);
  // };

  async function handleNext(values) {
    console.log("onClicK : ", values);

    const body = {
      first_name: formik5?.values?.first_name,
      last_name: formik5?.values?.last_name,
      full_name: `${formik5?.values?.first_name} ${formik5?.values?.last_name}`,
      email: formik5?.values?.email,
      dob: formik5?.values?.dob,
      // password: formik5?.values?.password,
      user_type: "guest",
    };

    setUserData(body);
    setValidUser(true);
    setActiveStep(1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // ---Modal---

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filledStars = (ratingStar) => {
    return Array.from({ length: 5 }, (_, index) => (
      <i
        key={index}
        className={`fas fa-star ${index < Math.floor(ratingStar) ? "filled" : ""
          }`}
      ></i>
    ));
  };

  const leftHandler = () => {
    if (ind !== 0) {
      setInd(ind - 1);
      console.log(ind, data?.clinic_documents[ind]);
    } else {
      console.log("no left");
      return;
    }
  };

  const rightHandler = () => {
    if (ind !== length - 1) {
      setInd(ind + 1);
      console.log(ind, data?.clinic_documents[ind]);
    } else {
      console.log("no right");

      return;
    }
  };

  const dateHandler = (val) => {
    const dateObject = new Date(val);

    const options = { day: "2-digit", month: "long" };
    const formattedDate = dateObject?.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  function formatCustomDate(date) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek}${dayOfMonth} ${month} ${year}`;
  }

  // const dateHandler1 = (e, mode, newDateWithOffset) => {
  //   let originalDate = "";
  //   if (mode === "arrowClick") {
  //     originalDate = newDateWithOffset;
  //     setSelectedDate(newDateWithOffset);
  //   } else {
  //     originalDate = new Date(e.target.value);
  //     setSelectedDate(e.target.value);
  //   }

  //   const dayOfWeek1 = originalDate.getDay();

  //   const dayName = daysOfWeek[dayOfWeek1];
  //   const behindDays = formik1.values[dayName];

  //   const beyondDays = 7 - parseInt(formik1.values[dayName]);

  //   const startingDate = new Date(originalDate);

  //   startingDate.setDate(originalDate.getDate() - behindDays);

  //   const utcConve = startingDate;

  //   setStartDate(timeConversionForFilter(utcConve));

  //   const EndDate = new Date(originalDate);
  //   EndDate.setDate(originalDate.getDate() + beyondDays);
  //   const utcConver = EndDate;

  //   setEndDate(timeConversionForFilter(utcConver));

  //   let currentDate = new Date(startingDate);
  //   let i = 0;
  //   while (currentDate <= EndDate) {
  //     formik.setFieldValue(
  //       daysOfWeek[currentDate.getDay()],
  //       formatCustomDate(currentDate)
  //     );
  //     console.log(formatCustomDate(currentDate));
  //     currentDate.setDate(currentDate.getDate() + 1); // Increment to the next day
  //   }
  // };

  function timeConversionForFilter(date) {
    const startInputTime = new Date(date);
    startInputTime.setHours(0, 0, 0, 0);
    return startInputTime.toISOString();
  }

  const slotsCollector = (d, v, selectedSlot, date) => {
    if (v?.status === "selected") {
      return;
    } else if (v?.status === "available") {
      setTickedDate(date);
      setTickedSlot(selectedSlot);
    }
  };

  const [rating, setRating] = useState(0);

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleSubmitRate = () => {
    // You can implement your submission logic here, like sending the rating to a backend server.
    console.log("Submitting Rating:", rating);
    // Reset rating after submission if needed
    setRating(0);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var settingsReview = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var providerImages = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  //OUR DENTIST
  const [ourDentists, setOurDentists] = useState([]);
  console.log("🚀 ~ Appointment ~ ourDentists:", ourDentists)

  useEffect(() => {
    getOurDentists();
  }, []);

  const getOurDentists = async () => {
    const params = {
      branch_id: id,
    };
    try {
      const resp = await makeHttpRequest(
        "get",
        "users/dentistListing",
        null,
        params
      );
      setOurDentists(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  function getDayRange(slots) {
    if (slots.length === 0) {
      return "";
    }
    const days = slots
      .filter(
        (slot) => slot.enabled && slot.slot?.some((slotTime) => slotTime.enable)
      )
      .map((slot) => slot.day);

    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    const sixDaysWorking = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const allDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    if (allDays.every((day) => days.includes(day))) {
      return "Monday - Sunday";
    } else if (allDays.every((day) => days.includes(day))) {
      return "Monday - Saturday";
    } else if (allDays.every((day) => days.includes(day))) {
      return "Monday - Friday";
    } else {
      return days.join(", ");
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling behavior
    });
  };

  // ---button to scroll-top---

  // ---button to scroll-top---end

  const getLatestRating = async (brandId) => {
    const limit = 10;
    try {
      const resp = await makeHttpRequest(
        "get",
        `users/public/getLatestRating/${brandId}`
      );
      setLatestReviews(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(latestReviews);
  const transformWorkingHoursData = (workingHoursData) => {
    const transformedData = {};

    const generateTimeSlots = (startTime, endTime) => {
      const slots = [];
      // Round start time up to the nearest hour
      const roundedStartTime = startTime.clone().startOf("hour").add(1, "hour");
      // Round end time down to the nearest hour
      const roundedEndTime = endTime.clone().startOf("hour");

      let currentSlot = roundedStartTime.clone();
      const endTimeObj = roundedEndTime.clone();

      while (currentSlot.isBefore(endTimeObj)) {
        slots.push(
          currentSlot.format("h:mm A") +
          " - " +
          currentSlot.add(1, "hour").format("h:mm A")
        );
      }
      return slots;
    };

    workingHoursData.forEach((entry) => {
      const { day, start_time, end_time } = entry;
      const startTime = moment(start_time);
      const endTime = moment(end_time);
      transformedData[day] = generateTimeSlots(startTime, endTime);
    });

    return transformedData;
  };

  const fetchClinicData = async () => {
    if (id) {
      const token1 = localStorage.getItem("token");
      const user = localStorage.getItem("user_details");
      let user1 = JSON.parse(user);
      console.log(user1);

      if (token1 != null && user && user1?.user_type === "user") {
        setTokken(token1);
        setUserData(user1);
        setActiveStep(1);
      }

      const clinic_data = localStorage.getItem("seraching_details");
      let variable = JSON.parse(clinic_data);
      setInsurance(variable?.insurance);
      try {
        let resp;
        let resp1;
        const token = localStorage.getItem("token");
        const user_data = localStorage.getItem("user_details");

        if (
          token != null &&
          user_data != null &&
          user_data?.user_type === "user"
        ) {
          resp = await getClinicsData(id);
          resp1 = await getProviderLists(id);
        } else {
          resp = await getClinicData(id);
          resp1 = await getProviderList(id);
        }
        let generatedSlots = transformWorkingHoursData(
          resp?.data[0].working_hours
        );
        setAvailableSlots(generatedSlots);
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  function convertDataToDaySlots(data) {
    const daySlots = {};

    data.forEach((item) => {
      const { day, slot } = item;
      const slots = slot.map(({ start, end }) => {
        const startTime = new Date(start)?.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        const endTime = new Date(end)?.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        return `${startTime} - ${endTime}`;
      });
      daySlots[day] = slots;
    });

    return daySlots;
  }

  const [newDate, setNewDate] = useState("");

  const getDentistSlots = async (selected_date) => {
    // if (dentist !== "") {
    //   const response = await getSlots(dentist, day, newDate, selectedDate);
    //   let result = convertDataToDaySlots(response.data.slots);
    //   setAvailableSlots(result);
    // }
    try {
      const isTimeOffsetPositive = new Date().getTimezoneOffset() > 0;
      const day = selected_date.format("dddd");
      const dateString = selected_date.format("YYYY-MM-DD");


      let date = new Date(dateString.toString());

      // Increment the date by one day if the timezone offset is positive
      if (isTimeOffsetPositive) {
        date.setDate(date.getDate() + 1);
      }

      // Reset hours, minutes, seconds, and milliseconds to 0
      date.setHours(0, 0, 0, 0);

      const isoDateString = date.toISOString();
      console.log("🚀 ~ getClinicDentistSlots ~ date, isTimeOffsetPositive:", date)

      const response = await getSlots(dentist, day,
        isoDateString,
        dateString
      )

      console.log(response, "myresponse");

      convertTimetoNewFormatttedTime(response.data.todaySlot);

    } catch (err) {
      console.error("Error", err.message);
    }

  };

  const getBookedSlots = async (payloadDate) => {
    if (dentist) {
      // Convert payloadDate to local date object
      const date = new Date(payloadDate);

      // Set time to the beginning of the day in local timezone
      date.setHours(0, 0, 0, 0);

      // Clone the date object to avoid modifying the original date
      const startDate = new Date(date);
      const endDate = new Date(date);

      // Set time to the end of the day in local timezone
      endDate.setHours(23, 59, 59, 999);

      // Convert startDate and endDate to UTC ISO strings
      const adjustedStartDate = startDate.toISOString();
      const adjustedEndDate = endDate.toISOString();

      const params = {
        dentist_id: dentist,
        start_date: adjustedStartDate,
        end_date: adjustedEndDate,
      };

      console.log(params, "paramssss");

      const response = await bookedSlot(params);
      setAllBookedSlots(response.data);
      return response.data;
    }
  };

  useEffect(() => {
    if (dentist) {
      getDentistSlots(selectedDate);
    } else {
      getClinicDentistSlots(selectedDate);
      fetchClinicData();
    }
  }, [id, dentist]);

  useEffect(() => {
    getBookedSlots(selectedDate);
  }, [dentist, id]);

  const getDayOfWeek = (date) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[date.getDay()];
  };
  async function createTimeRange(jsonData) {
    console.log(jsonData, "jsonData");

    if (!jsonData) {
      console.error("jsonData is null or undefined");
      return [];
    }

    // Check if jsonData is an array
    if (!Array.isArray(jsonData)) {
      console.error("jsonData is not an array");
      return [];
    }

    return jsonData.map((item) => {
      const { date, selected, value } = item;

      let start_time, end_time;

      if (date && value) {
        const [day, month, year] = date.split("/");
        const selectedDate = new Date(year, month - 1, day);

        // Set start_time and end_time to 23:59:00 on the same date
        start_time = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          23,
          59,
          0
        );

        end_time = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          23,
          59,
          0
        );
      } else {
        start_time = new Date(0, 0, 0, 0, 0, 0);
        end_time = new Date(0, 0, 0, 0, 0, 0);
      }

      return {
        value,
        start_time,
        end_time,
      };
    });
  }

  async function checkAvailability(data, slots, timeZone) {
    function convertTime(value) {
      const [timeStr, period] = value.split(" ");
      let [hour, minute] = timeStr.split(":").map(Number);

      if (period.toUpperCase() === "PM" && hour !== 12) {
        hour += 12;
      } else if (period.toUpperCase() === "AM" && hour === 12) {
        hour = 0;
      }

      return { hour, minute };
    }

    function convertTimeToUTC(dateStr, startTime, endTime, timeZone) {
      const startTimeMoment = moment.tz(dateStr, "YYYY-MM-DD", timeZone).set({
        hour: startTime.hour,
        minute: startTime.minute,
      });

      let endTimeMoment = moment.tz(dateStr, "YYYY-MM-DD", timeZone).set({
        hour: endTime.hour,
        minute: endTime.minute,
      });

      // If the end time is before the start time, increment the end time by 1 day
      if (endTimeMoment.isBefore(startTimeMoment)) {
        endTimeMoment = endTimeMoment.add(1, "day");
      }

      const startTimeUTC = startTimeMoment.utc().toISOString();
      const endTimeUTC = endTimeMoment.utc().toISOString();

      return { utcStartDateTime: startTimeUTC, utcEndDateTime: endTimeUTC };
    }

    for (const item of data) {
      const [valueStartTime, valueEndTime] = item.value.split(" - ");
      const { hour: valueStartHour, minute: valueStartMinute } =
        convertTime(valueStartTime);
      const { hour: valueEndHour, minute: valueEndMinute } =
        convertTime(valueEndTime);

      const itemDate = moment.tz(item.start_time, timeZone);
      const itemDateString = itemDate.format("YYYY-MM-DD");

      const { utcStartDateTime, utcEndDateTime } = convertTimeToUTC(
        itemDateString,
        { hour: valueStartHour, minute: valueStartMinute },
        { hour: valueEndHour, minute: valueEndMinute },
        timeZone
      );

      item.matched = false;

      for (const slot of slots) {
        const slotStart = moment.tz(slot.start, timeZone).utc().toISOString();
        const slotEnd = moment.tz(slot.end, timeZone).utc().toISOString();

        if (utcStartDateTime === slotStart && utcEndDateTime === slotEnd) {
          item.matched = true;
          break;
        }
      }
    }

    return data;
  }
  const handleNextAvailableDate = () => {
    if (!availableSlots || typeof availableSlots !== "object") {
      console.error("availableSlots is not an object or is undefined");
      setAvailableSlots({});
      return;
    }

    const today = new Date();
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDay = weekdays[today.getDay()];
    let nextAvailableDay = null;
    let nextAvailableDayIndex = -1;

    // Check for available day in the current week and wrap around to the next week if needed
    let i = weekdays.indexOf(currentDay) + 1;
    let checked = 0;
    while (checked < weekdays.length) {
      const day = weekdays[i % weekdays.length];
      console.log(day, "availableSlotssssss");
      if (availableSlots[day]?.length > 0) {
        nextAvailableDay = day;
        nextAvailableDayIndex = i % weekdays.length;
        break;
      }
      i++;
      checked++;
    }

    // Handle the case when no available day is found in the entire week
    if (nextAvailableDay) {
      const nextAvailableDate = new Date();
      const daysToAdd = nextAvailableDayIndex - today.getDay();
      if (daysToAdd < 0) {
        // If nextAvailableDay is in the next week
        nextAvailableDate.setDate(nextAvailableDate.getDate() + 7 + daysToAdd);
      } else {
        // If nextAvailableDay is in the same week
        nextAvailableDate.setDate(nextAvailableDate.getDate() + daysToAdd);
      }
      console.log("Next available date:", nextAvailableDate);
      setSelectedDate(nextAvailableDate);

      handleDateChange(nextAvailableDate);
    } else {
      console.log("No available day found in the entire week");
      // Handle the case when no available day is found
    }
  };

  function isEmpty(obj) {
    return (
      Object.keys(obj).length === 0 ||
      Object.values(obj).every((value) => value.length === 0)
    );
  }

  // useEffect(() => {
  //   if (!isEmpty(availableSlots)) {
  //     handleNextAvailableDate();
  //   } else {
  //     console.log("no slots available");
  //     setSlotsData([]);
  //   }
  // }, [availableSlots]);


  const handleDateChange = async (date) => {
    const selectedDate = new Date(date);

    getClinicDentistSlots(selectedDate);
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = selectedDate.toLocaleDateString("en-US", options);
    setTickedDate(formattedDate);

    setSelectedDate(date);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = moment.tz(date, userTimeZone);
    const newDate = localDate.toISOString();
    const dateString = localDate.format("YYYY-MM-DD");

    setNewDate(newDate);
    setDateString(dateString);
    setSelectedDate(localDate);


    return;

    // const selectedDayOfWeek = localDate.format("dddd");
    // setDay(selectedDayOfWeek);

    // let result = availableSlots[selectedDayOfWeek]?.map((item) => ({
    //   date: localDate.format("DD/MM/YYYY"),
    //   value: item,
    // }));

    // let newResults = await createTimeRange(result);

    // if (dentist) {
    //   const dateresponse = await getBookedSlots(date);
    //   const resultedSlots = await checkAvailability(
    //     newResults,
    //     dateresponse,
    //     userTimeZone
    //   );
    //   console.log("🚀 ~ handleDateChange ~ resultedSlots:", resultedSlots, dateresponse, result)
    //   console.log("🚀 ~ handleDateChange ~ resultedSlots: availableSlots", availableSlots, newResults)
    //   setSlotsData(resultedSlots);
    // } else {
    //   console.log(allDentistSlots, "alllllllldebistssssSlots");
    //   if (allDentistSlots.length > 0) {
    //     setSlotsData(allDentistSlots);
    //   }
    // }
  };

  const tileDisabled = ({ date }) => {
    const day = date.toLocaleDateString("en-US", { weekday: "long" });
    return !availableSlots[day];
  };

  const handleDentist = (e) => {
    setDentist(e.target.value);
    // handleNextAvailableDate();
  };

  const handleSlotClick = (slot) => {
    if (slot?.isSlotBooked) {
      return;
    } else {
      setTickedSlot(slot.value);
      setSelectedSlotOrignal(slot?.originalSlot)
      handleClickOpen();
    }
  };


  // ---bookingConfirmed Popup--

  const [showConfirmedBook, setShowConfirmedBook] = useState(false);

  const handleCloseConfirmedBook = () => {
    setShowConfirmedBook(false);
    if (dentist) {
      getDentistSlots(selectedDate)
    } else {
      getClinicDentistSlots(selectedDate);
    }
    setTickedDate("")
    setTickedSlot("")
  }
  const handleShowConfirmedBook = () => setShowConfirmedBook(true);

  const navigateToChat = () => {
    setShowConfirmedBook(false)
    
    setTimeout(() => {
      navigate(`/chat-box?clinicId=${id}`)
    }, 300);
  }

  // Get today's date
  const today = new Date();

  // Copy the date to avoid mutating the original date object
  const dateInFuture = new Date(today);

  // Add 30 days to the current date
  dateInFuture.setDate(dateInFuture.getDate() + 30);

  return (

    <>
      <Header showbackToMatches={from !== "profile"}/>
      <div className="page-wrap bg-colors">
        <section className="appointment section-pdding">


          <BookConfirmed
            show={showConfirmedBook}
            handleContinue={navigateToChat}
            handleClose={handleCloseConfirmedBook}
            appointmentDetails={{
              userDetails: userData,
              appointmentDate: tickedDate,
              appointmentTime: tickedSlot,
              dentistDetails: providerDetails,
              questionnairDetails: questionnairDetails
            }}

          />

          <div className="container">
            <div className="row">
              {/* --clinic--Profile--- */}
              <div
                className="sigma_post-details-inner shadow border-0 p-0"
                style={{ display: isVisible == true ? "block" : "none" }}
                id="clinicHeader"
              >
                <div className="sigma_team style-17 mb-0 bg-white rounded">
                  <div className="row g-3">
                    <div className="col-md-4 mb-md-0 mb-3">
                      <div className="sigma_team-thumb">
                        <img
                          src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${data?.profile_image}`}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="sigma_team-body ">
                        <h4>
                          <Link className="Clinic_title" href="#">
                            {data?.practice_name}
                          </Link>
                        </h4>
                        <h6>{`${data?.address}${data?.distance !== null ? `, ${data?.distance}` : ""
                          }`}</h6>

                        {data?.total_count > 0 &&
                          <div className="sigma_rating">
                            {filledStars(data?.rating)}
                            
                            <p>{data?.rating}/5 <span className="ms-1">{`(${data?.total_count} Reviews)`}</span></p>
                          </div>
                        }

                        {latestReviews.length > 0 &&
                          <div className="most_review my-3 d-none d-md-block">
                            <h5>Most Helpful Reviews</h5>
                            <Slider {...settingsReview}>
                              {
                                latestReviews?.map((item) => (
                                  <div className="mstHelpContner">
                                    <div className="most_helpful_review">
                                      <img
                                        src={
                                          item?.user?.profile_image
                                            ? profile_image_base_url +
                                            item?.user?.profile_image
                                            : process.env
                                              .REACT_APP_DEFAULT_IMAGE_URL
                                        }
                                        className="avatar"
                                        alt=""
                                      />
                                      <p>{item?.user?.full_name}</p>
                                    </div>
                                    <p className="px-2">{item?.reviews}</p>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        }

                        {/* <div className="clinic_images">
                          <ul className="">
                            {ourDentists?.map(
                              (obj) =>
                                obj?.profile_image !== "" && (
                                  <li>
                                    <img
                                      src={
                                        profile_image_base_url +
                                        obj?.profile_image
                                      }
                                      className="avatar"
                                      alt=""
                                    />
                                  </li>
                                )
                            )}
                          
                          </ul>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card__doc-info-right">
                        <div className="card__clini-infos">
                          <ul>
                            {/* <li><i className="far fa-thumbs-up"></i> 96%</li> */}
                            <li>
                              <i className="far fa-comment"></i>{" "}
                              {data?.reviews_count} feedback
                            </li>
                            {/* <li><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="login" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 01520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 01270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 010 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z"></path></svg> in Network</li> */}
                            <li>
                              <i className="fa-solid fa-arrow-trend-up"></i>{" "}
                              Booked by {data?.appointmnet_count} people in the
                              last 30 days
                            </li>
                          </ul>
                        </div>
                        <div className="card__clinic-booking">
                          <Link
                            className="apt-btn theme_btn rounded-1"
                            onClick={toggleVisibility}
                            to=""
                          >
                            Book Appointment
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* --clinic--Profile---End */}
              {/* ---Schedule Appointment-- */}

              <div
                className="appoitment_Card shadow py-3 bg-white rounded"
                style={{ display: isVisible == true ? "none" : "block" }}
                id="appointment-card"
              >
                {/* <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-xl-4">
                    <h4 className="mb-1">{presentDate}</h4>
                    <p className="text-muted">{Day1}</p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-8 col-xl-8 p-0 ">
                    <div className="d-flex flex-sm-row flex-column gap-5 w-100 align-items-center">
                      <select
                        className="form-select provider_select"
                        aria-label="Default select example"
                        onChange={(e) => sloHandler(e.target.value)}
                        value={providerId}
                      >

                        {providerList.map((val) => (
                          <option value={val?._id} label={val?.full_name} />
                        ))}
                        <option value="Any" label="Any" />
                      </select>

                      <input
                        type="date"
                        onChange={dateHandler1}
                        min={getCurrentDate()}
                        className="form-control"  
                      />
                    </div>
                  </div>
                </div> */}

                <div className="card border-0 booking-schedule mb-3">
                  <div className="row ">
                    <div className="col-md-7  col-sm-12">
                      <div className="card mb-md-0 mb-4 shadow-sm p-md-4 p-2 providr_list">
                        <div
                          className="providr_list_header pb-3 border-bottom-1 "
                          style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "white",
                            zIndex: 1,
                          }}
                        >
                          <h5 className="mb-0 text-center ">Select Dentist</h5>
                          {providerList?.length > 0 && (
                            <Radio.Group
                              onChange={(e) => handleDentist(e)}
                              value={dentist}
                            >
                              <div
                                className="d-none"
                                style={{
                                  // minHeight: "200px",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                  paddingTop: "10px",
                                }}
                              >
                                <div

                                  className="d-flex justify-content-between align-items-center p-2"
                                >
                                  <div className="d-flex align-items-center ">
                                    {
                                      ourDentists?.map(dentist => (
                                        <img
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1713937438~exp=1713938038~hmac=fb974aa6e18dd7dd93e96222618332ed9458af7b5e7d6831e9bbf94eb68f3e22";
                                          }}
                                          src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${dentist?.profile_image}`}
                                          alt={dentist.full_name}
                                          className="rounded-circle me-3 object-fit-cover"
                                          style={{ width: 40, height: 40 }} // Adjust image size if needed
                                        />
                                      ))
                                    }

                                    <p className="mb-0 ms-2">
                                      Any provider
                                    </p>
                                  </div>

                                  <Radio value="" />
                                </div>
                                {/* {providerList.map((option) => (
                                  <div
                                    key={option._id}
                                    className="d-flex justify-content-between align-items-center p-2"
                                  >
                                    <div className="d-flex align-items-center ">
                                      <img
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1713937438~exp=1713938038~hmac=fb974aa6e18dd7dd93e96222618332ed9458af7b5e7d6831e9bbf94eb68f3e22";
                                        }}
                                        src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${option?.profile_image}`}
                                        alt={option.full_name}
                                        className="rounded-circle me-3 object-fit-cover"
                                        style={{ width: 40, height: 40 }} // Adjust image size if needed
                                      />
                                      <p className="mb-0 ms-2">
                                        {option.full_name}
                                      </p>
                                    </div>

                                    <Radio value={option._id} />
                                  </div>
                                ))} */}
                              </div>
                            </Radio.Group>
                          )}

                          {providerList?.length === 0 && (
                            <div className="">
                              <p>No dentist available in this clinic</p>
                            </div>
                          )}
                        </div>

                        <div className="d-block">
                          <CustomSelect
                            data={providerList}
                            handleClick={(e) => {
                              setDentist(e);
                              // handleNextAvailableDate();
                            }}
                            dentistList={ourDentists}
                          />
                          <div className="custom-calendar mt-2 mb-md-0 mb-4">
                            <Calendar
                              onChange={handleDateChange}
                              tileDisabled={tileDisabled}
                              value={selectedDate}
                              minDate={new Date()}
                              maxDate={dateInFuture}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12">
                      <div className="time_slot_list mb-md-0">
                        {/* <ul>
                          <li>
                            <a href="" className="timing">
                              <span>
                                <i class="fa-regular fa-clock"></i> 09:00 -
                                09:30
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="" className="timing">
                              <span>
                                <i class="fa-regular fa-clock"></i> 09:00 -
                                09:30
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="" className="timing">
                              <span>
                                <i class="fa-regular fa-clock"></i> 09:00 -
                                09:30
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="" className="timing">
                              <span>
                                <i class="fa-regular fa-clock"></i> 09:00 -
                                09:30
                              </span>
                            </a>
                          </li>
                        </ul> */}
                        <ul>
                          {slotsData?.length > 0 ? (
                            slotsData.map((item) => (
                              <li
                                key={item._id}
                                disabled={item.isSlotBooked}
                                style={{
                                  cursor: item.matched ? "default" : "pointer",
                                }}
                                onClick={() => handleSlotClick(item)}
                              >
                                {item.isSlotBooked ? (
                                  <a
                                    className="timing"
                                    style={{
                                      backgroundColor: "grey",
                                      border: "none",
                                    }}
                                  >
                                    <span>
                                      <i className="fa-regular fa-clock"></i>{" "}
                                      {/* {item.value} */}
                                      {item.value?.split('-')?.[0] || '' }
                                    </span>
                                  </a>
                                ) : (
                                  <a className="timing">
                                    <span>
                                      <i className="fa-regular fa-clock"></i>{" "}
                                      {item.value?.split('-')?.[0] || '' }
                                    </span>
                                    {tickedSlot === item.value && (
                                      <span style={{ marginLeft: "5px" }}>
                                        ✓
                                      </span>
                                    )}
                                  </a>
                                )}
                              </li>
                            ))
                          ) : (
                            <li>
                              {" "}
                              <Empty description="No slots available" />{" "}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* <div className="col-md-4 col-sm-7">
                      <div className="custom-calendar mb-md-0 mb-4">
                        <Calendar
                          onChange={handleDateChange}
                          tileDisabled={tileDisabled}
                          value={selectedDate}
                          minDate={new Date()}
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="container">
                        <div className="card border-0 shadow providr_list">
                          <div className="providr_list_header p-3 border-bottom-1">
                            <h5 className="mb-2">Select Dentist</h5>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="userSelection"
                                onChange={(e) => setDentist("")}
                                id="noPreference"
                                value=""
                                checked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="noPreference"
                              >
                                I don't have any preference
                              </label>
                            </div>
                          </div>
                          <ul className="list-group-providers p-3">
                            {providerList.map((option) => (
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center"
                                key={option._id}
                              >
                                <label htmlFor={option._id} className="w-100">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://via.placeholder.com/50"
                                      alt={option.full_name}
                                      className="rounded-circle me-3"
                                    />
                                    <span>{option.full_name}</span>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="userSelection"
                                      onChange={handleDentist}
                                      id={option._id}
                                      value={option._id}
                                    />
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    {/* <Select
                      defaultValue={dentist}
                      style={{ width: 120 }}
                      onChange={handleDentist}
                    >
                      <Select.Option value="">Any</Select.Option>{" "}
                      Default value
                      {providerList.map((option) => (
                        <Select.Option
                          key={option._id}
                          value={option._id}
                          disabled={option.disabled}
                        >
                          {option.full_name}
                        </Select.Option>
                      ))}
                    </Select> */}
                  </div>
                  {/* <div className="schedule-header">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="day-slot">
                          <ul>
                            <li className="left-arrow">
                              <Link to="" onClick={() => incrementWeek("back")}>
                                <i className="fa fa-chevron-left"></i>
                              </Link>
                            </li>
                            <li>
                              <p className="appt_day">Mon</p>
                              <span>{formik?.values?.Monday?.slice(3)}</span>
                            </li>
                            <li>
                              <p className="appt_day">Tue</p>
                              <span>{formik?.values?.Tuesday?.slice(3)}</span>
                            </li>
                            <li>
                              <p className="appt_day">Mon</p>
                              <span>{formik.values.Wednesday?.slice(3)}</span>
                              <span className="slot-date">13 Nov <small className="slot-year">2023</small></span>
                            </li>
                            <li>
                              <p className="appt_day">Thu</p>
                              <span>{formik.values.Thursday?.slice(3)}</span>
                              <span className="slot-date">14 Nov <small className="slot-year">2023</small></span>
                            </li>
                            <li>
                              <p className="appt_day">Fri</p>
                              <span>{formik.values.Friday?.slice(3)}</span>
                              <span className="slot-date">15 Nov <small className="slot-year">2023</small></span>
                            </li>
                            <li>
                              <p className="appt_day">Sat</p>
                              <span>{formik.values.Saturday?.slice(3)}</span>
                              <span className="slot-date">16 Nov <small className="slot-year">2023</small></span>
                            </li>
                            <li>
                              <p className="appt_day">Sun</p>
                              <span>{formik.values.Sunday?.slice(3)}</span>
                              <span className="slot-date">17 Nov <small className="slot-year">2023</small></span>
                            </li>
                            <li className="right-arrow">
                              <Link onClick={() => incrementWeek("next")}>
                                <i className="fa fa-chevron-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="schedule-cont">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="time-slot">
                          <ul className="clearfix">
                            <li>
                              {formik2?.values?.Monday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik?.values?.Monday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  }  ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Monday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !== formik?.values?.Monday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Monday",
                                      val,
                                      val?.formatTime,
                                      formik?.values?.Monday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  {console.log(val?.status)}
                                </Link>
                              ))}
                            </li>
                            <li>
                              {formik2?.values?.Tuesday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik.values.Tuesday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  } ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Tuesday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !== formik.values.Tuesday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Tuesday",
                                      val,
                                      val?.formatTime,
                                      formik.values.Tuesday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  <span>AM</span>
                                </Link>
                              ))}
                            </li>
                            <li>
                              {formik2?.values?.Wednesday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik.values.Wednesday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  } ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Wednesday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !==
                                          formik.values.Wednesday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Wednesday",
                                      val,
                                      val?.formatTime,
                                      formik.values.Wednesday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  <span>AM</span>
                                </Link>
                              ))}
                            </li>
                            <li>
                              {formik2?.values?.Thursday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik.values.Thursday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  } ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Thursday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !== formik.values.Thursday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Thursday",
                                      val,
                                      val?.formatTime,
                                      formik.values.Thursday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  <span>AM</span>
                                </Link>
                              ))}
                            </li>
                            <li>
                              {formik2?.values?.Friday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik.values.Friday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  } ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Friday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !== formik.values.Friday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Friday",
                                      val,
                                      val?.formatTime,
                                      formik.values.Friday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  <span>AM</span>
                                </Link>
                              ))}
                            </li>
                            <li>
                              {formik2?.values?.Saturday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik.values.Saturday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  } ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Saturday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !== formik.values.Saturday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Saturday",
                                      val,
                                      val?.formatTime,
                                      formik.values.Saturday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  <span>AM</span>
                                </Link>
                              ))}
                            </li>
                            <li>
                              {formik2?.values?.Sunday?.map((val) => (
                                <Link
                                  className={`timing ${
                                    tickedDate === formik.values.Sunday &&
                                    tickedSlot === val?.formatTime
                                      ? "selected"
                                      : ""
                                  } ${
                                    tickedSlot === val?.formatTime &&
                                    tickedDate === formik.values.Sunday
                                      ? ""
                                      : tickedSlot === val?.formatTime &&
                                        tickedDate !== formik.values.Sunday &&
                                        val?.status === "available"
                                      ? "available"
                                      : tickedSlot !== val?.formatTime &&
                                        val?.status === "available"
                                      ? "available"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    slotsCollector(
                                      "Sunday",
                                      val,
                                      val?.formatTime,
                                      formik.values.Sunday
                                    )
                                  }
                                  disabled={`${
                                    val?.status === "selected" ? true : false
                                  }`}
                                >
                                  <span>{val?.formatTime}</span>
                                  <span>AM</span>
                                </Link>
                              ))}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <div className="d-flex gap-2 justify-content-center ">
                  <button
                    className="theme_btn rounded"
                    onClick={toggleVisibility}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    className="theme-outline-btn rounded"
                    onClick={handleClickOpen}
                  >
                    Request Booking
                  </button>
                </div> */}
              </div>

              {/* Schedule Appointment End  */}
            </div>
          </div>
        </section>
        <section className="appointment pt-0">
          <div className="container">
            {/* --Clinic Overview--- */}
            <div className="row">
              <div className="col-lg-8 ps-md-0">
                <div className="sigma_post-details-inner">
                  <div className="entry-content">
                    <ul className="detail-menu-list mb-4 rounded">
                      <li className="menu nav-item ">
                        <Link
                          to="overview"
                          spy={true}
                          smooth={true}
                          offset={-280}
                          duration={200}
                          activeClass="active"
                          onClick={() => setActiveDiv("overview")}
                        >
                          Overview
                        </Link>
                      </li>

                      <li className="menu nav-item ">
                        <Link
                          activeClass="active"
                          to="ourDentists"
                          spy={true}
                          smooth={true}
                          offset={-210}
                          duration={200}
                          // className={`nav-link ${
                          //   activeDiv === "ourDentists" ? "active" : ""
                          // }`}
                          onClick={() => setActiveDiv("ourDentists")}
                        >
                          Our Dentists
                        </Link>
                      </li>

                      <li className="menu nav-item">
                        <Link
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-190}
                          duration={200}
                          activeClass="active"
                          onClick={() => setActiveDiv("contact")}
                        >
                          Services
                        </Link>
                      </li>

                      <li className="menu nav-item border-0">
                        <Link
                          to="reviews"
                          spy={true}
                          smooth={true}
                          offset={-190}
                          duration={200}
                          activeClass="active"
                          onClick={() => setActiveDiv("reviews")}
                        >
                          Reviews
                        </Link>
                      </li>
                    </ul>
                    {/* <div className="spacer"></div> */}

                    <div
                      id="overview"
                      ref={overviewRef}
                      className="profile-container p-scton"
                    >
                      <div className="about-profile">
                        <div className="col-md-12">
                          <div className="widget photos-widget">
                            <h4 className="mb-3">Photos</h4>
                            {/* <p className=''>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum eveniet at voluptate quibusdam asperiores non eos velit vel omnis .</p> */}
                            {/* <div className="about-photos">
                              {length <= 5
                                ? data?.clinic_documents?.map((val, index) => (
                                  <div className="images" key={index}>
                                    <img
                                      src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${val}`}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                ))
                                : data?.clinic_documents
                                  ?.slice(0, 4)
                                  .map((val, index) => (
                                    <div className="images" key={index}>
                                      <img
                                        src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${val}`}
                                        className="clinicImages"
                                        alt=""
                                      />
                                    </div>
                                  ))}

                              {data?.clinic_documents?.length > 5 ? (
                                <div className="images"

                                >

                                  <img
                                    id="myImg"
                                    src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${data?.clinic_documents[ind]}`}
                                    className="img-fluid"
                                    alt="clinic"
                                    style={{ width: "100%", maxWidth: "200px" }}
                                    onClick={() => handleImagePopup("open")}
                                  />
                                  <div className="modal">
                                    <span
                                      onClick={() => handleImagePopup("close")}
                                      className="close"
                                    >
                                      &times;
                                    </span>
                                    <span
                                      className=" prev-btn"
                                      onClick={leftHandler}
                                    >
                                      <i className="fa-solid fa-circle-chevron-left"></i>
                                    </span>
                                    <div className="image-modal"

                                    >
                                      <img
                                        src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${data?.clinic_documents[ind]}`}
                                        className="modal-content"
                                        alt="doctor"
                                      />
                                    </div>

                                    <span
                                      className="next-btn"
                                      onClick={rightHandler}
                                    >
                                      <i className="fa-solid fa-circle-chevron-right"></i>
                                    </span>
                                  </div>
                                  <div id="image-view" className="view-all">
                                    <Link
                                      onClick={() => handleImagePopup("open")}
                                      className="view"
                                    >
                                      View All{" "}
                                    </Link>
                                  </div>
                                </div>
                              ) : null}
                            </div> */}
                            <ProviderImages images = {data?.clinic_documents} />

                          </div>

                          <div className="widget about-widget">
                            <h4 className="mb-3">About Us</h4>
                            {
                              console.log(data)
                            }
                            <p className="">{data?.bio}</p>
                          </div>

                          {/* <div className="widget awards-widget">
                                                        <h4 className="widget-title">Awards</h4>
                                                        <div className="experience-box">
                                                            <ul className="experience-list">
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <p className="exp-year">July 2023</p>
                                                                            <h5 className="exp-title">Humanitarian Award</h5>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <p className="exp-year">March 2011</p>
                                                                            <h5 className="exp-title">Certificate for International Volunteer Service</h5>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> */}

                          {/* <div className="widget highligts">

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="sigma_general-list style-3">
                                  <ul>
                                    <li>
                                      {data?.features_and_benefits?.map(
                                        (val) => (
                                          <div className="">
                                            <i className="fa-solid fa-check"></i>
                                            <span>{val}</span>
                                          </div>
                                        )
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="widget Business_hours">
                            <h4 className="mb-3">Business Hours</h4>
                            <div className="widget business-widget rounded">
                              <div className="widget-content">
                                <div className="listing-hours">
                                  <div className="listing-day current">
                                    <div className="day">
                                      Today <span>{presentDate}</span>
                                    </div>

                                    <div className="time-items">
                                      <span className="open-status">
                                        {open1 ? (
                                          <span className="badge bg-success">
                                            Open Now
                                          </span>
                                        ) : (
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        )}
                                      </span>
                                      <span className="time">
                                        {showTime ? test.get(Day1) : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="listing-day">
                                    <div className="day">Monday</div>
                                    <div className="time-items">
                                      {test?.get("Monday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Monday")}
                                        </span>
                                      )}
                                    </div>
                                    {/* {console.log("map1.get('Monday')", map1?.get('Monday'))} */}
                                  </div>
                                  <div className="listing-day">
                                    <div className="day">Tuesday</div>
                                    <div className="time-items">
                                      {test?.get("Tuesday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Tuesday")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="listing-day">
                                    <div className="day">Wednesday</div>
                                    <div className="time-items">
                                      {test?.get("Wednesday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Wednesday")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="listing-day">
                                    <div className="day">Thursday</div>
                                    <div className="time-items">
                                      {test?.get("Thursday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Thursday")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="listing-day">
                                    <div className="day">Friday</div>
                                    <div className="time-items">
                                      {test?.get("Friday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Friday")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="listing-day">
                                    <div className="day">Saturday</div>
                                    <div className="time-items">
                                      {test?.get("Saturday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Saturday")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="listing-day closed">
                                    <div className="day">Sunday</div>
                                    <div className="time-items">
                                      {test?.get("Sunday") === "Closed" ? (
                                        <span className="time">
                                          <span className="badge bg-danger">
                                            Closed
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="time">
                                          {test?.get("Sunday")}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="spacer"></div> */}
                    </div>
                    {/* <div className="spacer d-none d-sm-block"></div> */}
                    <div
                      id="ourDentists"
                      ref={ourDentistsRef}
                      className="widget our_providers"
                    >
                      <h4>Our Dentists</h4>
                      <div className="pt-2">
                        <Slider {...settings}>
                          {ourDentists?.map((obj) => (
                            <div>
                              <div className="card my-2 shadow media border-0 h-100">
                                <div className="prfie_hder d-flex align-items-center">
                                  <img
                                    className="card-img-top"
                                    src={
                                      obj?.profile_image !== ""
                                        ? profile_image_base_url +
                                        obj?.profile_image
                                        : profile01
                                    }
                                    alt="Provider 1"
                                  />
                                  <div className="ms-4 mt-2">
                                    <h5 className="card-title">
                                      {obj?.full_name}
                                    </h5>
                                    <p className="card-text mb-2">
                                      <strong>Experience:</strong>{" "}
                                      {obj?.experience} years
                                    </p>
                                    {/* <p className="card-text">
                                      <strong>Working Days:</strong>{" "}
                                      {getDayRange(obj?.slots)}
                                    </p> */}
                                  </div>
                                </div>
                                {/* <div className="card-body">
                                  <p className="card-text">
                                    <strong>Services:</strong>
                                  </p>
                                  <div className="service-list">
                                    <ul>
                                      {obj?.services?.map((item) => (
                                        <li>{item}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          ))}

                          {/* <div>
                            <div className="card media border-0 h-100">
                              <div className="prfie_hder d-flex align-items center">
                                <img
                                  className="card-img-top"
                                  src={profile01}
                                  alt="Provider 1"
                                />
                                <div className="ms-4 mt-2">
                                  <h5 className="card-title">John Doe</h5>
                                  <p className="card-text mb-2">
                                    <strong>Experience:</strong> 5 years
                                  </p>
                                  <p className="card-text">
                                    <strong>Working Days:</strong> Monday to
                                    Friday
                                  </p>
                                </div>
                              </div>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Services:</strong>
                                </p>
                                <div className="service-list">
                                  <ul>
                                    <li>Service</li>
                                    <li>Service</li>
                                    <li>Service</li>
                                    <li>Service</li>
                                    <li>Service</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </Slider>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div id="contact" ref={contactRef} className="p-scton">
                      <div className="ourservice">
                        <div className="service-list">
                          <h4 className="mb-3">Our Services</h4>
                          <ul className="clearfix">
                            {data?.services?.map((val, index) => (
                              <li key={index}>{val}</li>
                            ))}
                          </ul>
                        </div>
                        {/* <div className="service-list">
                                                    <h4>Specializations</h4>
                                                    <ul className="clearfix">
                                                        <li>Children Care</li>
                                                        <li>Dental Care</li>
                                                        <li>Oral and Maxillofacial Surgery </li>
                                                        <li>Orthodontist</li>
                                                        <li>Periodontist</li>
                                                        <li>Prosthodontics</li>
                                                    </ul>
                                                </div> */}
                      </div>

                      {/* <div className='d-flex justify-content-between align-items-center '>

                                                <h4 className="my-3">Your Selected Services</h4>
                                                <p>
                                                    <button className="theme_btn " type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                        View Your Selected Services
                                                    </button>
                                                </p>
                                            </div>

                                            <div className="collapse" id="collapseExample">
                                                <div className="card p-4">
                                                    <p className="mb-2">
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard.
                                                    </p>
                                                    <div className="card-body p-0">
                                                        <div className="service-categories style-3">
                                                            <ul className='tags'>
                                                                <li>
                                                                    <Link className='tag'>Bonding</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Bridges / Dentures</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Braces / Invisalign</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Checkup & Cleaning</Link>
                                                                </li>

                                                                <li>
                                                                    <Link className='tag'>Crown / Cap</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Tooth Extraction</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Urgent Issue</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Wisdom Teeth Removal</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Whitening</Link>
                                                                </li>

                                                            </ul>   
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div> */}
                    </div>

                    <div className="spacer"></div>

                    <div id="reviews" ref={reviewRef} className="p-scton">
                      <div className="review_header">
                        <h4 className="mb-0">Patient Reviews</h4>
                        <button
                          onClick={() => {
                            if (token) {
                              setOpenRateModal(true);
                            } else {
                              setOpenConfirmDialog(true);
                            }
                          }}
                          type="button"
                          className="theme-outline-sm"
                        >
                          Rate Now
                        </button>
                      </div>

                      <ConfirmPopUp
                        show={openConfirmDialag}
                        handleClose={() => setOpenConfirmDialog(false)}
                        handleSubmit={() => {
                          setOpenConfirmDialog(false);
                          setOpenAuthModal(true);
                        }}
                      />
                      <AuthComponent
                        show={openAuthModal}
                        handleClose={() => setOpenAuthModal(false)}
                        setUserData={setUserData}
                        setValidUser={setValidUser}
                      />
                      {/* --Rating-- */}
                      {
                        openRateModal &&
                        <RateClinicModal
                          show={openRateModal}
                          handleClose={() => setOpenRateModal(false)}
                          providerList={providerList}
                          branch_id={id}
                          updateParentComp={refectInitalData}
                        />

                      }

                      {/* --Rating-- */}

                      {/* {clinicRating?.length>0 && clinicRating.map((item)=>(
                        <div key={item._id}>

                        </div>
                      ))} */}
                      {patients1?.map((val) => (
                        <div className="sigma_testimonial style-14">
                          <div className="sigma_testimonial-thumb">
                            <img
                              src={
                                val?.user?.profile_image
                                  ? `${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.user?.profile_image}`
                                  : process.env.REACT_APP_DEFAULT_IMAGE_URL
                              }
                              alt="testimonial"
                            />
                          </div>
                          <div className="sigma_testimonial-body w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-block d-sm-flex  justify-content-between align-items-center w-100">
                                <div className="sigma_author-block">
                                  <h5 className="mb-0">
                                    {val?.user?.full_name}
                                  </h5>
                                </div>
                                <div className="sigma_rating ms-sm-4 ms-0 mt-2 mt-sm-0">
                                  {[1, 2, 3, 4, 5].map((index) => (
                                    <span
                                      key={index}
                                      style={{
                                        color:
                                          index <= val?.rating
                                            ? "#ff8fb0"
                                            : "gray",
                                        fontSize: "23px",
                                      }}
                                    >
                                      ★
                                    </span>
                                  ))}
                                </div>
                                <p className="mb-0 sigma_testimonial-date">
                                  {/* {dateHandler(val?.createdAt)} */}
                                  Dr. {val?.dentist_details?.full_name}
                                </p>
                              </div>
                            </div>
                            <p className="my-2">{val?.reviews}</p>
                            <div className="review_date">
                              <p className="">
                                Reviewed on{" "}
                                <span className="date">
                                  {moment(val?.createdAt).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* <div className="sigma_testimonial style-14">
                                                <div className="sigma_testimonial-thumb">
                                                    <img
                                                        src={user01}
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="sigma_testimonial-body">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-block d-sm-flex align-items-center">
                                                            <div className="sigma_author-block">
                                                                <h5 className='mb-0'>Jose Bradley</h5>
                                                            </div>
                                                            <div className="sigma_rating ms-sm-4 ms-0 mt-2 mt-sm-0">
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="far fa-star" />
                                                                <span className="ms-3">(4/5)</span>
                                                            </div>
                                                        </div>
                                                        <span className="sigma_testimonial-date">07 March</span>
                                                    </div>
                                                    <p>
                                                        "It is a long established fact that a reader will be
                                                        distracted by the readable content of a page when looking
                                                        at its layout."
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="sigma_testimonial style-14">
                                                <div className="sigma_testimonial-thumb">
                                                    <img
                                                        src={user03}
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="sigma_testimonial-body">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-block d-sm-flex align-items-center">
                                                            <div className="sigma_author-block">
                                                                <h5 className='mb-0'>Vincent Reyes</h5>
                                                            </div>
                                                            <div className="sigma_rating ms-sm-4 ms-0 mt-2 mt-sm-0">
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="far fa-star" />
                                                                <span className="ms-3">(4/5)</span>
                                                            </div>
                                                        </div>
                                                        <span className="sigma_testimonial-date">07 March</span>
                                                    </div>
                                                    <p>
                                                        "It is a long established fact that a reader will be
                                                        distracted by the readable content of a page when looking
                                                        at its layout."
                                                    </p>
                                                </div>
                                            </div> */}
                      {count > 2 && limit === 2 ? (
                        <button
                          className="theme_btn text-white "
                          onClick={() => setLimit(count)}
                        >
                          See More <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      ) : count > 2 ? (
                        <button
                          className="theme_btn text-white "
                          onClick={() => setLimit(2)}
                        >
                          See Less <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* Sidebar Start */}
              <div className="col-lg-4 pe-md-0">
                <div className="sidebar style-10 mt-4 mt-lg-0">
                  <div
                    onClick={() => {
                      scrollToTop();
                      setIsVisible(false);
                    }}
                    className={`col-12 mb-2 DeskbookButton tabbutton ${showScrollBook ? "" : "hidden"
                      }  `}
                  >
                    <button className="">
                      <CalendarMonth /> Book Now
                    </button>
                  </div>
                  <div className="sigma_contact-map mb-3">
                    {lat && lng && (
                      <div className="appntment_map">
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                          }}
                          defaultCenter={defaultProps.center}
                          defaultZoom={defaultProps.zoom}
                        >
                          <AnyReactComponent
                            // lat={localStorage.getItem('lat')}
                            // lng={localStorage.getItem('lng')}
                            // text="My Marker"
                            {...locationCoordinates}
                          />
                        </GoogleMapReact>
                      </div>
                    )}
                  </div>

                  {/* Author Widget */}

                  {/* Contact Widget */}
                  {/* <div className="widget">
                    <h5 className="widget-title">Contact</h5>
                    <div className="widget-inner">
                      <div className="card sigma_info shadow-none">
                        <div className="row align-items-center g-0">
                          <div className="col-md-3 col-sm-3 col-3">
                            <div className="sigma_info-title">
                              <span className="sigma_info-icon bg-primary-1 text-white">
                                <i className="fa-solid fa-phone-volume"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-9 col-sm-9 col-9">
                            <div className="card-body contct-txt p-0">

                              <p className="mb-0">{data?.phone_number}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card sigma_info ">
                        <div className="row align-items-center g-0">
                          <div className="col-md-3 col-sm-3 col-3">
                            <div className="sigma_info-title">
                              <span className="sigma_info-icon bg-primary-1 text-white">
                                <i className="fa-solid fa-envelope"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-9 col-sm-9 col-9">
                            <div className="card-body contct-txt p-0">
                              <p className="mb-0">Main Email: {data?.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card sigma_info shadow-none">
                        <div className="row align-items-center g-0">
                          <div className="col-md-3 col-sm-3 col-3">
                            <div className="sigma_info-title">
                              <span className="sigma_info-icon bg-primary-1 text-white">
                                <i className="fa-solid fa-map-location-dot"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-9 col-sm-9 col-9">
                            <div className="card-body contct-txt p-0">

                              <p className="mb-0">{data?.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* Sidebar End */}
            </div>

            {/* ----Clinic Overview End--- */}
          </div>

          {/* ---Dialogue Box For Booking---- */}

          <div
            className="submit-section proceed-btn text-center"
            id="mui-dialogue"
          >

            <div>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="modalContainer"
              >

                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent className="modalContainer-inner">
                  <div className="booking w-100">
                    <Box sx={{ width: "100%" }}>
                      <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                          <Step key={index} completed={completed[index]}>
                            <StepLabel
                              color="inherit"
                              onClick={() => handleStep(index)}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>

                      <div>
                        {allStepsCompleted() ? (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              All steps completed - you&apos;re finished
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                              }}
                            >
                              <Box sx={{ flex: "1 1 auto" }} />
                              <Button onClick={handleReset}>Reset</Button>
                            </Box>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                              {stepContent[activeStep]?.content}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                              }}
                            >
                              {token === "" ? (
                                <Button
                                  color="inherit"
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{ mr: 1 }}
                                >
                                  Back
                                </Button>
                              ) : null}
                              <Box sx={{ flex: "1 1 auto" }} />
                              {activeStep === 1 ? (
                                <Button onClick={submitHandler} disabled={bookingLoading} sx={{ mr: 1 }}>
                                  Request Appointment
                                </Button>
                              ) : (
                                <Button
                                  onClick={formik5.submitForm}
                                  sx={{ mr: 1 }}
                                >
                                  Next
                                </Button>
                              )}
                              {/* {activeStep !== steps.length &&
                                                                                (completed[activeStep] ? (
                                                                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                                                        Step {activeStep + 1} already completed
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <Button onClick={handleComplete}>
                                                                                        {completedSteps() === totalSteps() - 1
                                                                                            ? 'Finish'
                                                                                            : 'Complete Step'}
                                                                                    </Button>
                                                                                ))} */}
                            </Box>
                          </React.Fragment>
                        )}
                      </div>
                    </Box>
                  </div>
                </DialogContent>
                {/* <DialogActions>
                                            <Button autoFocus onClick={handleClose}>
                                                Save changes
                                            </Button>
                                        </DialogActions> */}
              </BootstrapDialog>
            </div>
          </div>
          {/* <div className="container-fluid">
            <div className="book_now">
            
            </div>
          </div> */}
          {/* <Tooltip
            title="Book Appointment"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            arrow
            placement="top-start"
          > */}
          {/* </Tooltip> */}
          {showScrollBook && (
            <IconButton
              className={`scroll-to-top-btn d-block d-sm-none theme_btn 
          `}
              onClick={() => {
                scrollToTop();
                setIsVisible(false);
              }}
            >
              {" "}
              {/* <ArrowUpwardIcon className="move_up" /> */}
              <span className="fw-bold">Book Now</span>
            </IconButton>
          )}
        </section>

        <Footer />
      </div>
    </>
  );
}
