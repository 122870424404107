import { MenuItem } from "@mui/material";

export const convertDateToTimeString = (slot) => {
    const start = new Date(slot?.start)?.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const end = new Date(slot?.end)?.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return `${start} to ${end}`;
  };

  export const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  export   function generateHoursOptions(selectedStartTime) {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0'); 
      const time = `${hour}:00`; 
      const isDisabled = selectedStartTime && time <= selectedStartTime;
      options.push(
        <MenuItem key={i} value={time} disabled={isDisabled}>
          {time}
        </MenuItem>
      );
    }
    return options;
  }

  export function getUserId(){
     const user = JSON.parse(localStorage.getItem("user_details"))
     return user?._id || null;
  }

 export function capitalizeFirstChar(sentence) {
  
    let capitalizedWord = sentence.charAt(0).toUpperCase() + sentence.slice(1);

    return capitalizedWord;
}

export function getisoDate(selected_date){
  const isTimeOffsetPositive = new Date().getTimezoneOffset() > 0;
  const dateString = selected_date.format("YYYY-MM-DD");


  let date = new Date(dateString.toString());

  // Increment the date by one day if the timezone offset is positive
  if (isTimeOffsetPositive) {
    date.setDate(date.getDate() + 1);
  }

  // Reset hours, minutes, seconds, and milliseconds to 0
  date.setHours(0, 0, 0, 0);

 return date.toISOString();
}


export function getisoDateForDateObj(selectedSlot){
  const isTimeOffsetPositive = new Date().getTimezoneOffset() > 0;
  
  const dateString = new Date(selectedSlot);
  console.log("🚀 ~ getisoDateForDataObj ~ dateString:", dateString, { selectedSlot })


  let date = new Date(dateString);
  console.log("🚀 ~ getisoDateForDataObj ~ date:", date)

  // Increment the date by one day if the timezone offset is positive
  if (isTimeOffsetPositive) {
    date.setDate(date.getDate() + 1);
  }

  // Reset hours, minutes, seconds, and milliseconds to 0
  date.setHours(0, 0, 0, 0);

  return date.toISOString();
}

export const trackCustomEvent = (eventName, eventObj) => {
  if (window.fbq) {
    window.fbq('trackCustom', eventName, eventObj);
    alert("Meta Event Tracked Successfully");
  }

  if (window.ttq) {
    window.ttq.track(eventName, eventObj);
    alert("Tiktok Event Tracked Successfully");
  }
};